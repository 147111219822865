import React, { Fragment } from 'react'
import {connect} from "react-redux";
import PropTypes, {element} from 'prop-types'
import ApplicationSettings from "./ApplicationSettings";
import CustomerUsers from "./CustomerUsers";
import TargetSettings from "./TargetSettings";
import {withTranslation} from "react-i18next";
import { Row, Card } from 'react-bootstrap';
function Dashboard(props) {
    const { t } = props
    return (
        <Fragment>
            <div className="container mt-5">
                <h1>{props.customer.customer !== undefined ? props.customer.customer.name_long : ''}</h1>
                <Card>
                    <Card.Header as="h2">{t("Settings")}</Card.Header>
                    <Card.Body>
                        <ApplicationSettings/>
                    </Card.Body>
                </Card>
                {props.customer.targetSelectionEnabled &&
                <Card>
                    <Card.Header as="h2">{t("Targets")}</Card.Header>
                    <Card.Body>
                        <TargetSettings />
                    </Card.Body>
                </Card>
                }
                <Card>
                    <Card.Header as="h2">{t("Users")}</Card.Header>
                    <Card.Body>
                        <CustomerUsers />
                    </Card.Body>
                </Card>
            </div>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    customer: state.customersReducer.customer
});

export default connect(mapStateToProps)(withTranslation()(Dashboard))

export const GET_VEHICLES = "GET_VEHICLES";
export const DELETE_VEHICLE = "DELETE_VEHICLE";
export const ADD_VEHICLE = "ADD_VEHICLE";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const ADD_CUSTOMER = "ADD_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const GET_ERRORS = "GET_ERRORS";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const GET_MESSAGES = "GET_MESSAGES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ANPRS = "GET_ANPRS";
export const MQTT_CONNECTED = "MQTT_CONNECTED";
export const MQTT_ARRIVED = "MQTT_ARRIVED";
export const MQTT_DISCONNECTED = "MQTT_DISCONNECTED";
export const MQTT_SUBSCRIBED = "MQTT_SUBSCRIBED";
export const MQTT_UNSUBSCRIBED = "MQTT_UNSUBSCRIBED";
export const GET_DEVICES = "GET_DEVICES";
export const GET_WEIGHINGS = "GET_WEIGHINGS";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const PASS_DIFFERENCE = "PASS_DIFFERENCE";
export const FINISH_WEIGHING = "FINISH_WEIGHING";
export const RENEW_WEIGHING = "RENEW_WEIGHING";
export const LOADING_WEIGHINGS = "LOADING_WEIGHINGS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_PLATE_IN_WEIGHING = "UPDATE_PLATE_IN_WEIGHING"
export const UPDATE_DRIVER_IN_WEIGHING = "UPDATE_DRIVER_IN_WEIGHING"
export const GET_TARGETS = "GET_TARGETS"
export const DELETE_TARGET = "DELETE_TARGET"
export const UPDATE_TARGET = "UPDATE_TARGET"
export const ADD_TARGET = "ADD_TARGET"
export const UPDATE_TARGET_IN_WEIGHING = "UPDATE_TARGET_IN_WEIGHING"
export const GET_TRANSITS = "GET_TRANSITS"
export const UPDATE_EXTERNAL_DOCS_IN_WEIGHING = "UPDATE_EXTERNAL_DOCS_IN_WEIGHING"
export const UPDATE_TRANSIT_IN_PROGRESS = "UPDATE_TRANSIT_IN_PROGRESS"
export const UPDATE_WEIGHING_IN_PROGRESS = "UPDATE_WEIGHING_IN_PROGRESS"
export const UPDATE_INFO_IN_WEIGHING = "UPDATE_DRIVER_IN_WEIGHING"

import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import { Col, Row, Form, FormLabel, FormControl, Button } from 'react-bootstrap';
import { updateUser } from "../../actions/users"

function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

function areTheseObjectsEqual(first, second) {
    const al = Object.getOwnPropertyNames(first);
    const bl = Object.getOwnPropertyNames(second);
    console.log(first)
    console.log(second)
    // Check if the two list of keys are the same
    // length. If they are not, we know the objects
    // are not equal.
    if (al.length !== bl.length) return false;
    
    // Check that all keys from both objects match
    // are present on both objects.
    const hasAllKeys = al.every(value => !!bl.find(v => v === value));
  
    // If not all the keys match, we know the
    // objects are not equal.
    if (!hasAllKeys) return false;
    
    // We can now check that the value of each
    // key matches its corresponding key in the
    // other object.
    for (const key of al) {
        if (Array.isArray(first[key])) {
            if (!arrayEquals(first[key], second[key])) return false  
        } else  {
            if (first[key] !== second[key]) return false
        }
    };
    // If the object hasn't return yet, at this
    // point we know that the objects are the
    // same
    return true;
  }

export class CustomerUsers extends Component {

    state = {
        customer: {
            parent_maintainer: {
                admins: [],
                users: []
            },
            users: []
        }
    };
    onSettingChange = (event) => {
        const settingid = event.target.getAttribute("settingid");
        const value = event.target.value; 
        this.setState(prevState => ({
            ...this.state,
            applications: prevState.applications.map(application => ({
                ...application,
                settings: application.settings.map(setting => setting.id == settingid ? {...setting, settingValue: value}: setting)
            }))
        }))
    }
    
    componentDidMount() {
        this.setState({
            ...this.state,
            customer: JSON.parse(JSON.stringify(this.props.customer))
        })
    }
    onFirstNameChange(user, event) {
        console.log(event.target.value)
        user.first_name = event.target.value
        this.setState(prevState => ({
            ...this.state,
            customer: {
                ...this.state.customer,
                users: prevState.customer.users.map(usr => usr.id === user.id ? {...usr, first_name: user.first_name} : usr)
            }
        }))
    }
    onLastNameChange(user, event) {
        console.log(event.target.value)
        user.last_name = event.target.value
        this.setState(prevState => ({
            ...this.state,
            customer: {
                ...this.state.customer,
                users: prevState.customer.users.map(usr => usr.id === user.id ? {...usr, last_name: user.last_name} : usr)
            }
        }))
    }
    onEmailChange(user, event) {
        user.email = event.target.value
        this.setState(prevState => ({
            ...this.state,
            customer: {
                ...this.state.customer,
                users: prevState.customer.users.map(usr => usr.id == user.id ? {...usr, email: user.email, username: user.email} : usr)
            }
        }))
    }
    onPasswordChange(user, event) {
        user.password = event.target.value
        this.setState(prevState => ({
            ...this.state,
            customer: {
                ...this.state.customer,
                users: prevState.customer.users.map(usr => usr.id == user.id ? {...usr, password: user.password} : usr)
            }
        }))
    }
    onSaveUser(user, event) {
        this.props.updateUser(user)
        this.setState(prevState => ({
            ...this.state,
            customer: {
                ...this.state.customer,
                users: this.state.customer.users.map(({password,...usr}) => usr)
            }
        }))
    }
    render() {
        const customer = this.state.customer
        const parent_maintainer = customer.parent_maintainer;
        let users = []
        if (parent_maintainer !== undefined) {
            const parentUsers = parent_maintainer.admins.concat(parent_maintainer.users)
            users = customer.users.filter((user, key) => !parentUsers.includes(user.id) ? user : false)
            console.log(users)
        }
        const { t } = this.props
        return (
            <Form>
                {users.length > 0 && users.map((user, key) => (
                    <Row key={key} className="align-items-end pb-3">
                            <Col>
                                <FormLabel>{t("First name")}</FormLabel>
                                <FormControl onChange={this.onFirstNameChange.bind(this, user)} type="text" value={user.first_name}/>
                            </Col>
                            <Col>
                                <FormLabel>{t("Last name")}</FormLabel>
                                <FormControl onChange={this.onLastNameChange.bind(this, user)} type="text" value={user.last_name}/>
                            </Col>
                            <Col>
                                <FormLabel>{t("Email")}/{t("Login")}</FormLabel>
                                <FormControl onChange={this.onEmailChange.bind(this, user)} type="text" value={user.email}/>
                            </Col>
                            <Col>
                                <FormLabel>{t("Password")}</FormLabel>
                                <FormControl onChange={this.onPasswordChange.bind(this, user)} type="password" value={user.password}/>
                            </Col>
                            <Col xs={1}>
                                {!areTheseObjectsEqual(this.props.customer.users.find(usr => usr.id === user.id),user) &&
                                <Button onClick={this.onSaveUser.bind(this, user)}>{t("Save")}</Button>}
                            </Col>
                    </Row>
                ))}
            </Form>
        )

    }
}    
const mapStateToProps = state => ({
    customer: state.customersReducer.customer.customer
});
export default connect(mapStateToProps,{updateUser})(withTranslation()(CustomerUsers));

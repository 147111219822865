import axios from 'axios';
import { tokenConfig} from "./auth";
import {GET_DEVICES} from "./types";

export const getDevices = () => (dispatch, getState) => {
    axios.get('/api/v1/devices/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_DEVICES,
                payload: res.data
            });
        }).catch(err => console.log(err));
};
import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addVehicle } from '../../actions/vehicles';
import { getCustomers } from '../../actions/customers';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import {withTranslation} from "react-i18next";

export class Form extends Component {

    state = {
        selectedVehicle: {
            id: '',
            plate: '',
            vehicle_permission: 1,
            customer: '',
            maximum_weight: '40000',
        }
    };

    static propTypes = {
        addVehicle: PropTypes.func.isRequired,
        getCustomers: PropTypes.func.isRequired,
        customers: PropTypes.array.isRequired
    }
    onChange = e => this.setState({
        selectedVehicle: {
            ...this.state.selectedVehicle,
            [e.target.name]:e.target.value
        }
    });
    onSubmit = e => {
        e.preventDefault();
        const { id, plate, vehicle_permission, customer, maximum_weight } = this.state.selectedVehicle;
        console.log(customer.id, vehicle_permission, plate);
        const cid = customer.id;
        const vehicle = { id, plate, vehicle_permission, customer: cid, maximum_weight };
        if (vehicle.id) {
            this.props.updateVehicle(vehicle);
        } else {
            this.props.addVehicle(vehicle);
        }
        this.setState({
            selectedVehicle: {
                id: '',
                plate: '',
                vehicle_permission: 1,
                customer: '',
                maximum_weight: '40000',
            }
        })
    };
    onCustomerClick = (customer) => {
        this.setState({
            selectedVehicle: {
                ...this.state.selectedVehicle,
                customer: customer
            }
        });
        console.log(this.state);
    };

    componentDidMount() {
        this.props.getCustomers();
    }


    render() {
        const { plate, permission } = this.state.selectedVehicle;
        const { t } = this.props;
        return (
            <div className="card card-body mt-4 mb-4 px-5">
                <h2>{t("Add Vehicle")}</h2>
                <form onSubmit={this.onSubmit}>
                    <div className="row mt-2">
                        <div className="col-md-3 text-right mt-2">{t("Company")}:</div>
                        <div className="col-md-6 mt-1"><input className="form-control w-100 mx-3" type="text" disabled={true} value={this.state.selectedVehicle.customer.name_long || ''} name="company" onChange={this.onChange} /></div>
                        <div className="col-md-3 text-right pr-0 mt-1">
                            <DropdownButton className="wx-100" id="dropdown-basic-button" title={t("Select Company")}>
                                {this.props.customers.map(customer => (
                                    <Dropdown.Item key={customer.id} href="#" onClick={this.onCustomerClick.bind(this, customer)}>{customer.name_short}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-3 text-right mt-2">{t("Plate")}:</label>
                        <div className="col-md-3">
                            <input
                                className="form-control w-100 mx-3 mt-1"
                                type="text"
                                name="plate"
                                onChange={this.onChange}
                                value={plate}
                            />
                        </div>
                        <label className="col-md-3 text-right mt-2">{t("Access permission")}:</label>
                        <div className="col-md-3 mt-1">
                            <BootstrapSwitchButton
                                checked={true}
                                onlabel={t("Allow")}
                                onstyle='success'
                                offlabel={t("Deny")}
                                offstyle='danger'
                                style='w-100 mx-3'

                                onChange={checked => {
                                    this.setState({
                                        selectedVehicle: {
                                            ...this.state.selectedVehicle,
                                            vehicle_permission: checked ? 1 : 2
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-md-3 text-right mt-2">{t("Max weight(kg)")}:</div>
                        <div className="col-md-3 mt-1"><input className="form-control w-100 mx-3"
                            type="number" value={this.state.selectedVehicle.maximum_weight} name="maximum_weight" onChange={this.onChange} /> </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12 mt-5">
                            <Button variant="success" weight="1000px" type="submit">
                                {t("Submit")}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    customers: state.customersReducer.customers,
    vehicles: state.vehiclesReducer.vehicles
});
export default connect(mapStateToProps, { getCustomers, addVehicle })(withTranslation()(Form));

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCustomers, deleteCustomer, updateCustomer } from "../../actions/customers";
import MUIDataTable from "mui-datatables";
//import Form from './Form';

export class Customers extends Component {

    static propTypes = {
        customers: PropTypes.array.isRequired,
        getCustomers: PropTypes.func.isRequired,
        deleteCustomer: PropTypes.func.isRequired
    };

    state = {
        selectedCustomer: {
            id: '',
            vat_id: '',
            name_long: '',
            name_short: '',
            description: '',
            address: {},
            parent_maintainer: '',
        },
        columns: [
            {
              name: "id",
              options: {
                  display: 'false'
              }
            },
            {
                name: "name_short",
                label: "Name"
            },
            {
                name: "address.city",
                label: "City"
            },
            {
                name: "zip_code",
                label: "Zip code",
                options: {
                  display: 'false'
              }
            },
            {
                name: "vat_id",
                label: "Vat id",
                options: {
                  display: 'false'
              }
            },
            {
                name: "name_long",
                label: "Full name",
                options: {
                  display: 'false'
              }
            }
        ],
    }
    componentDidMount() {
        this.props.getCustomers();
    }
    handleRowClick = customer => {
        this.setState({
            selectedCustomer: customer
        });
    };


    render() {
        return (
            <Fragment>

                <MUIDataTable
                    columns = {this.state.columns}
                    data = {this.props.customers}
                    options = {{
                        responsive: 'scrollMaxHeight',
                        onRowsDelete: (rowsDeleted) => {
                            const idsToDelete = rowsDeleted.data.map(d => this.props.customers[d.dataIndex].id); // array of all ids to to be deleted
                            idsToDelete.map(deleted => this.props.deleteCustomer(deleted))
                        },
                        onRowClick: (rowData, rowMeta) => {
                            this.handleRowClick(this.props.customers[rowMeta.dataIndex]);
                        }
                    }}
                />
            </Fragment>

        );
    }
}

const mapStateToProps = state => ({
    customers: state.customersReducer.customers
});

export default connect(mapStateToProps, { getCustomers, deleteCustomer, updateCustomer })(Customers)
import axios from 'axios';
import {UPDATE_WEIGHING_IN_PROGRESS,LOADING_WEIGHINGS,GET_WEIGHINGS, PASS_DIFFERENCE, RENEW_WEIGHING, FINISH_WEIGHING, UPDATE_PLATE_IN_WEIGHING, UPDATE_DRIVER_IN_WEIGHING,UPDATE_TARGET_IN_WEIGHING,UPDATE_INFO_IN_WEIGHING, UPDATE_EXTERNAL_DOCS_IN_WEIGHING} from "./types";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig} from "./auth";

//require('axios-debug')(axios);

function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }

export const getWeighings = (pages,page_size,search_string) => (dispatch, getState) => {
    const updateWeighings = getState().weighingsReducer.update
    const updateTransits = getState().transitsReducer.update
    if (!updateWeighings) {
        dispatch({
          type: LOADING_WEIGHINGS,})

      let chain = Promise.resolve();
      let d = {
          data: [],
          next: ''
      }
      let promises = []
      let next = ''
      var sort_type = "desc"
      var sort_field = "last_updated"

      const sort = getState().customersReducer.customer.sortWeighingsBy
      if (sort !== undefined && sort !== '' && sort !== null && sort !==0 && sort !== false) {
        if (sort.substr(0,1) === '-') {
          sort_field = sort.substr(1)
        } else {
          sort_field = sort
          sort_type = "asc"
        }
      }
      for (let page in pages) {
          promises.push(
              axios.get('/api/v1/weighings/?page='+pages[page]+'&page_size='+page_size+search_string, tokenConfig(getState)).then(response => {
                // do something with response
                d.data = [...d.data,...response.data.results];
                d.count = response.data.count;
                d.data = d.data.sort(compareValues(sort_field,sort_type))
              })
          )};
      console.log(updateWeighings)
      if (!updateWeighings && !updateTransits) {
        Promise.all(promises).then(() =>
              dispatch({
                  type: GET_WEIGHINGS,
                  payload: d,
                  updateWeighings: getState().weighingsReducer.update
              }))
      
        }
      }
      
};
export const passDifference = (weighing) => (dispatch, getState) => {
    axios.patch(`/api/v1/weighings/${weighing.id}/`,weighing, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: PASS_DIFFERENCE,
               payload: res.data,
            });
        })
}
export const finishWeighing = (weighing) => (dispatch, getState) => {
    axios.patch(`/api/v1/finish-weighing/${weighing.id}/`,weighing, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: FINISH_WEIGHING,
               payload: res.data,
            });
        })
}
export const renewWeighing = (weighing) => (dispatch, getState) => {
    axios.patch(`/api/v1/renew-weighing/${weighing.id}/`,weighing, tokenConfig(getState))
        .then(res => {
            dispatch({
               type: RENEW_WEIGHING,
               payload: res.data,
            });
        })
}

export const updatePlateInWeighing = (weighing_id, plate) => (dispatch, getState) => {
  const data = {'weighing_id': weighing_id, 'plate': plate}
  axios.post('/api/v1/updateplatenumber/', data, tokenConfig(getState))
    .then(res =>{
        dispatch({
          type: UPDATE_PLATE_IN_WEIGHING,
          payload: res.data,
        })
    })
}
export const updateTargetInWeighing = (weighing_id, target_id) => (dispatch, getState) => {
  const data = {
    'target': target_id
  }
  axios.patch(`/api/v1/weighings/${weighing_id}/`, data, tokenConfig(getState))
  .then(res =>{
    dispatch(createMessage({targetUpdated: "Target updated"}))
    dispatch({
      type: UPDATE_TARGET_IN_WEIGHING,
      payload: res.data,
    })
    }).catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data,err.response.status));
      dispatch({
          type: AUTH_ERROR
      });
  })
}

export const updateDriver = (weighing_id, driver) => (dispatch, getState) => {
  const data = {
    'driver': driver,
  }
  axios.patch(`/api/v1/weighings/${weighing_id}/`, data, tokenConfig(getState))
  .then(res =>{
    dispatch(createMessage({driverUpdated: "Driver updated"}))
    dispatch({
      type: UPDATE_DRIVER_IN_WEIGHING,
      payload: res.data,
    })
    }).catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data,err.response.status));
      dispatch({
          type: AUTH_ERROR
      });
  })
}
export const updateExternalDocsInWeighing = (weighing_id, docs) => (dispatch, getState) => {
  const data = {
    'ext_docs': docs.map(doc => {
      if (doc.id!==null) {
        return {id: doc.id, name: doc.name, value: doc.value}
      } else {
        if (doc.name !== undefined && doc.name !== '' && doc.name !== null) {
        return {name: doc.name, value: doc.value}}
      }
    })
  }
  console.log(data)
  axios.patch(`/api/v1/weighings/${weighing_id}/`, data, tokenConfig(getState))
  .then(res =>{
    dispatch(createMessage({ExternalDOcsUpdated: "Documents updated"}))
    dispatch({
      type: UPDATE_EXTERNAL_DOCS_IN_WEIGHING,
      payload: res.data,
    })
    }).catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data,err.response.status));
      dispatch({
          type: AUTH_ERROR
      });
  })
}
export const editWeighingStart = () => (dispatch, getState) => {
  dispatch ({
      type: UPDATE_WEIGHING_IN_PROGRESS,
      payload: true
  })
}

export const updateInfoInWeighing = (weighing_id, info) => (dispatch, getState) => {
  const data = {
    'info': info
  }
  axios.patch(`/api/v1/weighings/${weighing_id}/`, data, tokenConfig(getState))
  .then(res =>{
    dispatch({
      type: UPDATE_INFO_IN_WEIGHING,
      payload: res.data,
    })
    }).catch(err => {
      console.log(err)
      dispatch(returnErrors(err.response.data,err.response.status));
      dispatch({
          type: AUTH_ERROR
      });
  })
}
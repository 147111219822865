import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import weighings from "../../reducers/weighings";
import Components from "./WeighingCard";
import { Form } from "react-bootstrap";
import { Row , Col} from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
// Or import the input component
import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";
import moment from "moment";
import {getWeighings} from "../../actions/weighings";
import {withTranslation} from "react-i18next";
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import 'react-image-lightbox/style.css';
import axios from 'axios'
import TransitList from '../transit/Transit'

class Download extends React.Component {
  state = {
    search: {
      plate: "",
      doc: "",
      selectedDateFrom: "",
      rawSelectedDateFrom: "",
      selectedDateTo: "",
      rawSelectedDateTo: "",
      isPassed: "",
    }
  }
  static propTypes = {
    search: PropTypes.object.isRequired
  };
  
  onDownloadClick = (search_string, config, count) => {
    axios.get('/api/v1/weighings-excel/?page=1&page_size='+count+search_string, config).then((response) => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'raport.xlsx'
      link.click()
    });
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      search: this.props.search
    })
  }
  render() {
      let search_string_plate = this.props.search.plate == "" ? "" : "&vehicle__plate__icontains="+this.props.search.plate
      let search_string_doc= this.props.search.doc == "" ? "" : "&external_documents__name__icontains="+this.props.search.doc
      let search_string_dateFrom = this.props.search.selectedDateFrom == "" ? "" : "&created__gte="+this.props.search.selectedDateFrom
      let search_string_dateTo = this.props.search.selectedDateTo == "" ? "" : "&created__lte="+this.props.search.selectedDateTo
      let search_string_isPassed = this.props.search.isPassed == "" ? "" : "&skip_difference="+this.props.search.isPassed
      let search_string = search_string_plate+search_string_doc+search_string_dateFrom+search_string_dateTo+search_string_isPassed
      
      const token = this.props.auth.token
      const config = {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'application/json'
        }
      };
      if (token) {
          config.headers['Authorization'] = `Token ${token}`;
      }
      
      return (
          <Button onClick={this.onDownloadClick.bind(this, search_string,config,this.props.count)}>Excel</Button>
      );
  }
}


class WeighingCards extends Component {
  state = {
    search: {
      plate: "",
      doc: "",
      selectedDateFrom: "",
      rawSelectedDateFrom: "",
      selectedDateTo: "",
      rawSelectedDateTo: "",
      isPassed: "",
      target: ""
    },
    loading: false,
    pages: [1],
    page_size: 30,
    joining: 0,
  };
  executeGetWeighing = () => {
    let search_string_plate = this.state.search.plate == "" ? "" : "&vehicle__plate__icontains="+this.state.search.plate
    let search_string_doc= this.state.search.doc == "" ? "" : "&external_documents__name__icontains="+this.state.search.doc
    let search_string_dateFrom = this.state.search.selectedDateFrom == "" ? "" : "&created__gte="+this.state.search.selectedDateFrom
    let search_string_dateTo = this.state.search.selectedDateTo == "" ? "" : "&created__lte="+this.state.search.selectedDateTo
    let search_string_isPassed = this.state.search.isPassed == "" ? "" : "&skip_difference="+this.state.search.isPassed
    let search_string_target = this.state.search.target == "" ? "" : "&target="+this.state.search.target
    let search_string = search_string_plate+search_string_doc+search_string_dateFrom+search_string_dateTo+search_string_isPassed+search_string_target
    
    this.props.getWeighings(this.state.pages, this.state.page_size,search_string)

  }
  handleDateFromChange = (selectedDateFrom, modifiers, dayPickerInput) => {
    console.log(selectedDateFrom, modifiers, dayPickerInput)
    const input = dayPickerInput.state;
    console.log(input)
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        rawSelectedDateFrom: selectedDateFrom,
        selectedDateFrom: input.value.trim()
      }
    });
    this.executeGetWeighing()
  };
  handleDateToChange = (selectedDateTo, modifiers, dayPickerInput) => {
    const input = dayPickerInput.state;
    console.log(input)
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        rawSelectedDateTo: selectedDateTo,
        selectedDateTo: input.value.trim() + " 23:59"
      }
    });
    this.executeGetWeighing()
  };
  static propTypes = {
    weighings: PropTypes.array.isRequired,
    getWeighings: PropTypes.func.isRequired
  };
  onSearchPlateChange = e => {
    console.log(e)
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        plate: e.target.value
      }
    });
    this.updateWeighingsNow(1000)
  };
  onSearchTargetChange = e => {
    console.log(e)
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        target: e.target.value
      }
    });
    this.updateWeighingsNow(1000)
  };
  
  onSearchDocChange = e => {
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        doc: e.target.value
      }
    });
    this.executeGetWeighing()
  };
  onJoinHandler = (weighingId) => {
    this.setState({
      ...this.state,
      joining: weighingId
    })
  }
  onJoinTargetHandler = (weighingId) => {
    this.setState({
      ...this.state,
      joinTarget: weighingId
    })
  }
  onSearchIsPassedChange = e => {
    this.setState({ ...this.state,
      pages: [1],
      search: {
        ...this.state.search,
        isPassed: e.target.checked
      }
    });
    this.executeGetWeighing()
  };
    updateTimeout;
  componentDidMount() {
    for (const page in this.state.pages) {
      this.executeGetWeighing()
    }
    this.updateWeighings();
  }
  updateWeighings = async () => {
      await this.executeGetWeighing()
      this.updateTimeout = setTimeout(this.updateWeighings, 10000);
  };
  updateWeighingsNow = async (time) => {
    setTimeout(this.executeGetWeighing, time);
  }
  fetchMoreData = (reload=false) => {
    if (!reload) {
      var next_page = Math.max(...this.state.pages)+1 
      this.setState({
        ...this.state,
        pages: [...this.state.pages, next_page]
      })
    }
  this.executeGetWeighing()
  }
  componentWillUnmount() {
    clearTimeout(this.updateTimeout);
  }
  render() {
    const { search, selectedDateFrom,selectedDateTo,isEmptyFrom,isEmptyTo, dateTo } = this.state;
    //console.log(this.props.weighings)
    /* let fw = []
    if (this.props.weighings !== undefined) {
        fw = this.props.weighings.filter(weighing => {
          return (
            weighing.anpr_transaction.plate
              .toLowerCase()
              .indexOf(search.toLowerCase()) !== -1 &&
            (
                isEmptyFrom || moment(weighing.created).date() >= moment(selectedDateFrom).date()
            ) && (
                isEmptyTo || moment(weighing.created).date() <= moment(selectedDateTo).date()
            )
          );
        });
    };
    const filteredWeighings = fw; */
    const filteredWeighings = this.props.weighings.filter(weighing => weighing.device.application.customer === this.props.customer.customer.id)
    const { t } = this.props;
    let hasMore = this.props.count > this.props.weighings.length
    return (
      <div>
        <Row className="justify-content-md-center mb-3">
          <Col md="10" xs="12">
            <Download search={this.state.search} auth={this.props.auth} count={this.props.count}/>
          </Col>
          <Col>
            <TransitList fetchMoreData={this.fetchMoreData}  onSearchPlateChange={this.onSearchPlateChange} handleRedirect={this.props.handleRedirect}/>
          </Col>
        </Row>
        
        <Form>
          <Row className="justify-content-md-center mb-3"> 
            <Col md="3">
              <Form.Label column={1} className="pb-0">
                {" "}
                {t("Plate")}{" "}
              </Form.Label>
              <Form.Control value={this.state.search.plate} onChange={this.onSearchPlateChange} type="text" />
            </Col>
            {this.props.customer.targetSelectionEnabled &&
              <Col>
                <Form.Label column={1} className="pb-0">
                  {" "}
                  {t("Target")}{" "}
                </Form.Label>
                <Form.Control value={this.state.search.target} onChange={this.onSearchTargetChange} as="select">
                  <option value="">...</option>
                  {this.props.targets.map((target, key) => (
                    <option key={target.id} value={target.id}>{target.short_name}</option>
                  ))}
                </Form.Control>
              </Col>
            }
            <Col md="3">
              <Form.Label column={1} className="pb-0">
                {" "}
                {t("Document")}{" "}
              </Form.Label>
              <Form.Control onChange={this.onSearchDocChange} type="text" />
            </Col>
            {false &&
            <div className="col-md pt-4">
            <Form.Check 
              onChange={this.onSearchIsPassedChange} type="checkbox"
              type={'checkbox'}
              id={'searchIsPassed'}
              label={'Is Passed'}
            />
            </div>
            }
            
            <Col md="2">
              <Form.Label column={1} className="pb-0">
                {" "}
                {t("Date from")}{" "}
              </Form.Label>
              <DayPickerInput
                component={props => <Form.Control type="text" {...props} />}
                inputProps={ { ref: null } }
                value={search.rawSelectedDateFrom}
                onDayChange={this.handleDateFromChange}
                dayPickerProps={{
                  selectedDays: search.rawSelectedDateFrom,
                }}
              />
            </Col>
            <Col md="2">
              <Form.Label column={1} className="pb-0">
                {" "}
                {t("Date to")}{" "}
              </Form.Label>
              <DayPickerInput
                component={props => <Form.Control type="text" {...props} />}
                inputProps={ { ref: null } }
                value={search.rawSelectedDateTo}
                onDayChange={this.handleDateToChange}
                dayPickerProps={{
                  selectedDays: search.rawSelectedDateTo,
                }}
              />
            </Col>
            </Row>
        </Form>
        
        {this.props.loading &&
        <div className="fixed-bottom">
        <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </div>
        }
        <Row className="justify-content-md-center"> 
        <Col md="10" className="pl-2 pr-2">
        <InfiniteScroll
          dataLength={this.props.weighings.length}
          next={this.fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
        {filteredWeighings.map((weighing, key) => (
          <Components.WeighingCard joinTargetHandler={this.onJoinTargetHandler} joinHandler={this.onJoinHandler} joiner={this.state.joining} key={key} weighing={weighing} user={this.props.user} />
        ))}
        </InfiniteScroll>
        </Col>
        </Row> 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    weighings: state.weighingsReducer.weighings,
    count: state.weighingsReducer.count,
    loading: state.weighingsReducer.loading,
    user: state.authReducer.user,
    auth: state.authReducer,
    customer: state.customersReducer.customer,
    targets: state.targetsReducer.targets

  };
}

export default connect(mapStateToProps, {getWeighings})(withTranslation()(WeighingCards));

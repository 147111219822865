import { GET_TRANSITS , UPDATE_TARGET_IN_WEIGHING, UPDATE_DRIVER_IN_WEIGHING, UPDATE_TRANSIT_IN_PROGRESS} from "../actions/types.js";

const initialState = {
    transits: [],
    update: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TRANSITS:
            return {
                ...state,
                transits: action.payload
            };
        case UPDATE_TARGET_IN_WEIGHING:
            return {
                ...state,
                transits: [...state.transits.map(transit => {
                    return transit.id === action.payload.id ? action.payload : transit;
                })],
                update: false
            }
        case UPDATE_DRIVER_IN_WEIGHING:
            return {
                ...state,
                transits: [...state.transits.map(transit => {
                    return transit.id === action.payload.id ? action.payload : transit;
                })],
                update: false
            }
        case UPDATE_TRANSIT_IN_PROGRESS:
            return {
                ...state,
                update: action.payload
            }
        default:
            return state;
    }
}
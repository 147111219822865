import {GET_DEVICES} from "../actions/types";


const initialState = {
    devices: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEVICES:
            return {
                devices: action.payload,
            };
        default:
            return state;
    }
}
import React, { Component, Fragment, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FormControl from "react-bootstrap/FormControl";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faAngleDoubleRight,
  faTruck
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { Badge, Card, Nav, Popover, OverlayTrigger, Form, Col, Row} from "react-bootstrap";
import weighings from "../../reducers/weighings";
import moment from "moment-timezone";
import { withTranslation } from "react-i18next";
import { updateInfoInWeighing,editWeighingStart, passDifference , finishWeighing , renewWeighing, updatePlateInWeighing, updateDriver, updateTargetInWeighing, updateExternalDocsInWeighing} from "../../actions/weighings";
import { getTargets, deleteTarget, addTarget } from "../../actions/targets";
import { lazyload } from 'react-lazyload';
import Lightbox from 'react-image-lightbox';
import Pdf from "./Pdf"
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

export class LightboxImage extends Component {
  state = {
    photoIndex: 0,
    isOpen: false,
  }
  render() {
    const { photoIndex, isOpen } = this.state
    var imgs = []
    imgs = this.props.weighing.images
    const images = imgs.slice().sort((a,b) => a.id - b.id)
    return (
      <div>
        <Card.Img
          src={
            images[0] !== undefined
              ? images[0].thumbnail
              : ""
          }
          onClick={() => this.setState({ isOpen: true })}
        />
        {isOpen && (
          <Lightbox
            imageTitle={this.props.weighing.vehicle.plate}
            imageCaption={this.props.weighing.weight}
            mainSrc={images[photoIndex].image}
            nextSrc={images[(photoIndex + 1) % images.length].image}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].image}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export class PassedComponent extends Component {

  render() {
    if (this.props.weighing.skip_difference) {
    const { t } = this.props;
    let first_name = ''
    let last_name = ''
    if (this.props.weighing.difference_user !== null && this.props.users !== undefined) {
      const duser = this.props.users.find(user => user.id === this.props.weighing.difference_user)
      if (duser !== undefined){
        first_name = duser.first_name
        last_name = duser.last_name
      }
    }
    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Passed by: {first_name} {last_name}</Popover.Title>
        <Popover.Content>
          {this.props.weighing.difference_description}
        </Popover.Content>
      </Popover>
    );
    const passed = this.props.weighing.skip_difference
    return (
      passed &&
      <OverlayTrigger trigger={['hover','click', 'focus']} placement="top" overlay={popover}>
        {passed && <span className="badge badge-primary ml-1">{t("Passed")}</span>}
      </OverlayTrigger>
    )
  } else {
    return ('')
  }
  }
}

export class TargetForm extends Component {
  state = {
    target: ''
  }
  static propTypes = {
    getTargets: PropTypes.func.isRequired,
    updateTargetInWeighing: PropTypes.func.isRequired,
    targets: PropTypes.array.isRequired,
    weighing: PropTypes.object.isRequired
  };

  componentDidMount() {
    var target = this.props.weighing.target
    if (!target) {
      target = ''
    }
    this.setState({
      ...this.state,
      target: target
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.weighing.target !== this.props.weighing.target) {
      var target = this.props.weighing.target
      if (!target) {
        target = ''
      }
      this.setState({
        ...this.state,
        target: target
      })
    }
  }
  handleChangeSelectTarget = (e) => {

    this.setState({
      ...this.state,
      target: e.target.value
    })
  }
  
  handleClick = (event) => {
    this.props.updateTargetInWeighing(this.props.weighing.id, this.state.target)
  }
  render() {
    const changed = this.props.weighing.target != this.state.target 
    var targets = this.props.targets
    const { t } = this.props
    return (
      <Form>
        <Form.Row>
          <Col>
            <Form.Control as="select" value={this.state.target} onChange={this.handleChangeSelectTarget}>
              <option value="0">{t("Select target")}</option>
              {targets.map((target, key) => (
                <option key={key} value={target.id}> {target.name}({target.target_type}) </option>
              ))}
            </Form.Control>
          </Col>
          {changed &&
          <Col xs="3">
            <Button className="btn-sm mt-1" type="submit" onClick={(event) => this.handleClick(event)}>
                  {t("Submit")}
            </Button>
          </Col>
          }

          </Form.Row>
      </Form>
    ) 
  }
}

export class InfoForm extends Component {
  state = {
    info: ''
  }
  componentDidMount() {
    this.setState({
      info: this.props.weighing.info
    })
  }
  componentDidUpdate(prevProps) {
    if (prevProps.weighing.info !== this.props.weighing.info) {
    this.setState({
      info: this.props.weighing.info
    })
    }
  }
  handleClick = (event) => {
    this.props.updateInfoInWeighing(this.props.weighing.id, this.state.info)
  }
  handleOnFocus = () => {
    this.props.editWeighingStart()
  }
  handleInfoChange = (event) => {
    let info = event.target.value;
    this.setState({
      ...this.state,
      info: info
    })
  }

  render() {
    const { t } = this.props;

    return <Form>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>{t("Info")}</Form.Label>
            <Form.Control as="textarea" value={this.state.info ? this.state.info : ''} placeholder="Info" onChange={this.handleInfoChange}
              onFocus={this.handleOnFocus} onBlur={(this.handleClick)}
            />
          </Form.Group>
        </Form.Row>
      </Form>
  }
}

export class DriverForm extends Component {
  state = {
    first_name: "",
    last_name: "",
    shipping_company_name: "",
    phone_number: "",
    email: ""
  }
  emptyDriver = {
    first_name: "",
    last_name: "",
    shipping_company_name: "",
    phone_number: "",
    email: ""
  }
  componentDidMount() {
    const driver = this.props.weighing.driver
    if (driver) {
      this.setState({
        ...this.state,
        first_name: driver.first_name,
        last_name: driver.last_name,
        shipping_company_name: driver.shipping_company_name,
        phone_number: driver.phone_number,
        email: driver.email
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.weighing.driver !== this.props.weighing.driver) {
      const driver = this.props.weighing.driver
      if (driver) {
      this.setState({
        first_name: driver.first_name,
        last_name: driver.last_name,
        shipping_company_name: driver.shipping_company_name,
        phone_number: driver.phone_number,
        email: driver.email
      })
    }
    }
  }
  handleFirstNameChange = (event) => {
    let first_name = event.target.value;
    this.setState({
      ...this.state,
      first_name: first_name
    })
  };
  handleLastNameChange = (event) => {
    let last_name = event.target.value;
    this.setState({
      ...this.state,
      last_name: last_name
    })
  };
  handleShippingCoNameChange = (event) => {
    let shipping_company_name = event.target.value;
    this.setState({
      ...this.state,
      shipping_company_name: shipping_company_name
    })
  };
  handlePhoneNumberChange = (event) => {
    let phone_number = event.target.value;
    this.setState({
      ...this.state,
      phone_number: phone_number
    })
  };
  handleEmailChange = (event) => {
    let email = event.target.value;
    this.setState({
      ...this.state,
      email: email
    })
  };
  handleClick = (event) => {
    this.props.updateDriver(this.props.weighing.id, this.state)
  }
  handleOnFocus = () => {
    this.props.editWeighingStart()
  }
  render() {
    const { t } = this.props;
    var driver = this.props.weighing.driver
    if (!driver) {
      driver = this.emptyDriver
    }
    var changes = false
    changes = this.state.first_name !== driver.first_name 
              || this.state.last_name !== driver.last_name
              || this.state.shipping_company_name !== driver.shipping_company_name
              || this.state.phone_number !== driver.phone_number
              || this.state.email !== driver.email
    return  <Form>
              <Form.Row>
                <Form.Group as={Col} controlId={"formFirstName" + this.props.weighing.id}>
                  <Form.Label>{t("First name")}</Form.Label> 
                  <Form.Control value={this.state.first_name} placeholder="Driver first name" onChange={this.handleFirstNameChange}
                    onFocus={this.handleOnFocus} onBlur={(this.handleClick)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId={"formLastName" + this.props.weighing.id}>
                  <Form.Label>{t("Last name")}</Form.Label>
                  <Form.Control value={this.state.last_name} placeholder="Driver last name" onChange={this.handleLastNameChange}/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId={"formShippingCompanyName" + this.props.weighing.id}>
                  <Form.Label>{t("Shipping co. name")}</Form.Label> 
                  <Form.Control value={this.state.shipping_company_name} placeholder="Shipping co. name" onChange={this.handleShippingCoNameChange}/>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId={"formPhoneNumber" + this.props.weighing.id}>
                  <Form.Label>{t("Phone number")}</Form.Label> 
                  <Form.Control value={this.state.phone_number} placeholder="Driver phone nr" onChange={this.handlePhoneNumberChange}/>
                </Form.Group>
                <Form.Group as={Col} controlId={"formEmail" + this.props.weighing.id}>
                  <Form.Label>{t("Email")}</Form.Label>
                  <Form.Control value={this.state.email} placeholder="Driver email" onChange={this.handleEmailChange}/>
                </Form.Group>
              </Form.Row>
              {changes && 
              <Button className="btn-sm" type="submit" onClick={(event) => this.handleClick(event)}>
                {t("Submit")}
              </Button>
              }
            </Form>   
  }

}
export class TypeIcon extends Component {
  static propTypes = {
    weighing: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };
  render() {
    const { t } = this.props;
    if (this.props.weighing.next_weighing !== null) {
      let realWeight =
        this.props.weighing.weight - this.props.weighing.next_weighing.weight;
      if (realWeight < 0) {
        return (
          <Fragment>
            <FontAwesomeIcon icon={faIndustry} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faTruck} />
            {"\u00a0"}| {t("goods dispatched")}
          </Fragment>
        );
      } else if (realWeight > 0) {
        return (
          <Fragment>
            <FontAwesomeIcon icon={faTruck} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faIndustry} />
            {"\u00a0"}| {t("goods received")}
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <FontAwesomeIcon icon={faIndustry} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faAngleDoubleRight} />
            {"\u00a0"}
            <FontAwesomeIcon icon={faIndustry} />
            {"\u00a0"}
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <FontAwesomeIcon icon={faIndustry} />
          {"\u00a0"}| Single weighing
        </Fragment>
      );
    }
  }
}
const RemoteCellEdit = (props) => {
  const cellEdit = {
    mode: 'dbclick',
    errorMessage: props.errorMessage,
    blurToSave: true,
    onStartEdit: (row, column, rowIndex, columnIndex) => {
      props.editWeighingStart();
      },

  };
  return (
    <div>
      <BootstrapTable 
        remote={ { cellEdit: true } }
        keyField='id'
        data={ props.data }
        columns={ props.columns }
        cellEdit={ props.allowEdit ? cellEditFactory(cellEdit) : false }
        onTableChange={ props.onTableChange }
        striped
        hever
        condensed
        bootstrap4
        sort= {{
          dataField: 'created',
          order: 'desc'
        }}
      />
    </div>
  );
}
const headerStyle = {
  position: "absolute"
};
const disableLinkStyle = {
  color: "inherit",
  textDecoration: "inherit"
};
const badgeStyle = {
  position: "absolute",
  bottom: "12px",
  right: "12px",
};
const descriptinStyle = {
  fontSize: "small",
  color: "grey"
};

@lazyload({
  height: 100,
  once: true,
  offset: 100
})
export class WeighingCard extends Component {

  state = {
    modalShow: false,
    ChangePlateModalShow: false,
    difference_description: '',
    skip_difference: false,
    hover: false,
    plate: '',
    external_documents: [],
    info: ''
  };
  static propTypes = {
    weighing: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    getTargets: PropTypes.func.isRequired
  };
  
  handleModalShow = () => {
    this.setState({
      ...this.state,
      modalShow: true
    });
  };
  handleModalHide = () => {
    this.setState({
      ...this.state,
      modalShow: false
    });
  };
  handleChangePlateModalHide = () => {
    this.setState({
      ...this.state,
      ChangePlateModalShow: false
    });
  };
  handleFinishClick = (event) => {
      const finishedWeighing = {
        id: this.props.weighing.id
      }
      this.props.finishWeighing(finishedWeighing)
  };
  handleRenewClick = (event) => {
    const renewingWeighing = {
      id: this.props.weighing.id
    }
    this.props.renewWeighing(renewingWeighing)
};
  handleClick = (event) => {
    const passWeighing = {
      id: this.props.weighing.id,
      difference_description: this.state.difference_description,
      skip_difference: true,
      difference_user: this.props.user.id,
    };
    this.props.passDifference(passWeighing);
    this.handleModalHide();
  };
  toggleHover = (event) => {
    this.setState({...this.state, hover: !this.state.hover})
  }
  handleModalTextChange = (event) => {
    let text = event.target.value;
    this.setState({
      ...this.state,
      difference_description: text
    })
  };
  handleChangePlateModalTextChange = (event) => {
    let text = event.target.value;
    this.setState({
      ...this.state,
      plate: text,
    })
  }
  handleChangePlateModalShow = () => {
    this.setState({
      ...this.state,
      ChangePlateModalShow: true,
      plate: this.props.weighing.vehicle.plate
    })
  }
  handleChangePlateClick = () => {
    this.props.updatePlateInWeighing(this.props.weighing.id, this.state.plate)
    this.handleChangePlateModalHide()
  }
  componentDidMount() {
    this.props.getTargets()
    this.setState({
      ...this.state,
      external_documents: this.props.weighing.external_documents
    })
  }
  handleAddDocToList = () => {
    this.setState({
      ...this.state,
      external_documents: [...this.props.weighing.external_documents, {id: null, name: '', value: 0}]
    })
  }
  handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    data.filter(entry => entry.id === rowId)[0][dataField] = newValue
    this.setState({
      ...this.state,
      external_documents: data
    })
    this.props.updateExternalDocsInWeighing(this.props.weighing.id, data)
  }

  handleSaveDocList = () => {
    this.props.updateExternalDocsInWeighing(this.props.weighing.id, this.state.external_documents)
  }
  render() {
    const { t } = this.props;
    
    let netto;
    const weighing = this.props.weighing;
    const next_weighing = weighing.next_weighing;
    if (weighing.previous_weighing === null) {
      const single = next_weighing === null || next_weighing === undefined;
      if (!single) {
        netto = Math.abs(weighing.weight - next_weighing.weight);
        const brutto =
          weighing.weight >= next_weighing.weight
            ? weighing.weight
            : next_weighing.weight;
        const tare = brutto - netto;
      } else {
        netto = weighing.weight;
      }
      const passed = this.props.weighing.skip_difference;
      const is_out = this.props.weighing.is_out;
      const finished = this.props.weighing.status == 4;
      var docs = [ ...this.props.weighing.external_documents, {id: null, name: '', value: 0}]
      if (!docs) {
        docs =[]      
      }
      var sum = docs.reduce((accum, item) => accum + parseInt(item.value), 0)
      
      const docsChanges = JSON.stringify(docs) !== JSON.stringify(this.props.weighing.external_documents);
      const disablePass = this.props.weighing.skip_difference || this.props.weighing.next_weighing !== null;
      const docsColumns = [
        {
          dataField: 'id',
          text: 'ID',
          editable: false,
          hidden: true
        },
        {
          dataField: 'name',
          text: 'Doc number',
          editable: true
        },
        {
          dataField: 'value',
          text: 'value',
          editable: true
        },
      ]
      const docsList = (//docs.map((doc) =>
          <RemoteCellEdit
            handleRedirect={this.props.handleRedirect}
            columns={docsColumns}
            data={ docs }
            errorMessage={ this.state.errorMessage }
            onTableChange={ this.handleTableChange }
            allowEdit={this.props.customer.allowDocumentsListEdit}
            editWeighingStart={this.props.editWeighingStart}
          />
        //<li key={doc.id}>{doc.name}: {Math.abs(doc.value)}kg ({doc.value > 0 && t("received")}{doc.value < 0 && t("dispatched")}{doc.value == 0 && t("zero")})</li>
      );

      var cardStyle;
      var cardClass;
      if (this.state.hover) {
        cardClass = 'shadow-lg';
      } else {
        cardClass = '';
      }
      var weighingDifferenceRedColor = (this.props.customer.customer.targetSelectionEnabled) ?
        (this.props.weighing.direction > 0 && this.props.weighing.difference < 0) || 
        (this.props.weighing.direction < 0 && this.props.weighing.difference > 0)
      :
        (this.props.weighing.difference < 0)
      return (
        <div>
          <Modal show={this.state.modalShow} onHide={this.handleModalHide}>
            <Modal.Header closeButton>
              <Modal.Title>{t('Pass description')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormControl as="textarea" aria-label="With textarea" onChange={this.handleModalTextChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleModalHide}>
                {t('Close')}
              </Button>
              <Button disabled={this.state.difference_description === ''} variant="primary" onClick={this.handleClick}>
                {t('Pass')}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.ChangePlateModalShow} onHide={this.handleChangePlateModalHide}>
            <Modal.Header closeButton>
              <Modal.Title>{t('Change plate')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormControl type="text" value={this.state.plate}  onChange={this.handleChangePlateModalTextChange} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleChangePlateModalHide}>
                {t('Close')}
              </Button>
              <Button variant="success" onClick={this.handleChangePlateClick}>
                {t('Save')}
              </Button>
            </Modal.Footer>
          </Modal>
         
          <div>
          <Card className={`mb-2 ${cardClass}`} style={cardStyle} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover} border=
          {this.props.weighing.difference !== 0 &&
          (this.props.weighing.difference > 0 && 'success' + this.props.weighing.difference < 0 && 'danger')}>
            <Tab.Container defaultActiveKey="overview">
              <Card.Header>
                <div className="row">
                  <div className="col-xs-12 col-md-5">
                    {!this.props.customer.targetSelectionEnabled &&
                      <TypeIcon weighing={this.props.weighing} t={this.props.t} user={this.props.user} />
                    }
                    {this.props.customer.targetSelectionEnabled &&
                      <TargetForm updateTargetInWeighing={this.props.updateTargetInWeighing} getTargets={this.props.getTargets} weighing={this.props.weighing} t={this.props.t} targets={this.props.targets}/>  
                    }
                  </div>
                  <div className='col-12 col-md-1'>
                    <div className="row">
                      <div className="col-6 text-center">
                      </div>
                      <div className="col-6 text-center">
                        
                      </div>
                    </div>
                  </div>
                  <Nav className="pl-2 pr-2 col-12 col-md-6 pt-2"
                    variant="tabs"
                    defaultActiveKey="overview"
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="overview">{t("Info")}</Nav.Link>
                    </Nav.Item>
                    {(docs.length > 0 || this.props.customer.docsTabAlwaysOn) &&
                      <Nav.Item>
                        <Nav.Link eventKey="docs">{t("Documents")}</Nav.Link>
                      </Nav.Item>
                    }
                    {this.props.customer.driverEnabled && 
                      <Nav.Item>
                        <Nav.Link eventKey="driver">{t("Driver")}</Nav.Link>
                      </Nav.Item>
                    }

                  </Nav>
                </div>
              </Card.Header>
              <Card.Body
                className="card-horizontal p-0"
                style={{ minHeight: "120px" }}
              >
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    
                    <div className="row ml-0 mr-0">
                      <div className="col-lg-4 col-md-2">
                        <div className="row">
                          
                          {single && is_out &&
                          <div className="col-lg-6 col-md-12 p-1">
                          </div>
                          }
                          <div className="col-lg-6 col-md-12 p-1">
                            <LightboxImage weighing={this.props.weighing}/>
                          </div>
                          {
                            !single &&
                            <div className="col-lg-6 col-md-12 p-1">
                              <LightboxImage weighing={this.props.weighing.next_weighing} />
                            </div>
                          }
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-3 p-1 text-left">
                        <dl>
                          <dt className="col-md-12 " style={descriptinStyle}>
                            {!single && <span>{t("First weighing")}</span>}
                            {single && <span>{t("Weighing")}</span>}
                            {false && <Badge variant="primary">{!single && weighing.weight < !single && next_weighing.weight ? t("tare") : t("brutto")}</Badge>}
                          </dt>
                          <dd className="col-md-12">{weighing.weight}</dd>
                          <dt className="col-md-12" style={descriptinStyle}>
                            Date
                                </dt>
                          <dd className="col-md-12">
                            <h6>
                              {moment(weighing.created)
                                .tz('Europe/Warsaw')
                                .format("DD-MM-YYYY HH:mm:ss")}
                            </h6>
                          </dd>
                        </dl>
                      </div>
                      {!single &&
                        <div className="col-lg-2 col-md-3 p-1 text-left">
                          <dl>
                            <dt className="col-md-12 " style={descriptinStyle}>
                              {t("Second weighing")} {false && <Badge variant="primary">{weighing.weight > next_weighing.weight ? t("tare") : t("brutto")}</Badge>}
                            </dt>
                            <dd className="col-md-12">{next_weighing.weight}</dd>
                            <dt className="col-md-12" style={descriptinStyle}>
                              Date
                                      </dt>
                            <dd className="col-md-12">
                              <h6>
                                {moment(next_weighing.created)
                                  .tz('Europe/Warsaw')
                                  .format("DD-MM-YYYY HH:mm:ss")}
                              </h6>
                            </dd>
                          </dl>
                        </div>
                      }
                      {single &&
                        <div className="col-md-3">
                        </div>
                      }
                      <div className="col-md-3 p-1 text-left">
                        <dl>
                          <dt className="col-md-12 " style={descriptinStyle}>
                            {t("Vehicle")}
                          </dt>
                          <dd className="col-md-12">{weighing.anpr_transaction.plate}</dd>
                          <dt className="col-md-12 " style={descriptinStyle}>
                            {t("Weighing")} <Badge variant="success">{t("netto")}</Badge>
                          </dt>
                          <dd className="col-md-12"><h4>{netto} kg</h4> </dd>


                        </dl>
                      </div>
                    </div>
                    
                  </Tab.Pane>
                  <Tab.Pane eventKey="docs">
                        <Row>
                          <Col>
                            {docsList}
                          </Col>
                        </Row>
                        <Row>
                          <div className="react-bootstrap-table">
                            <table className="table table-striped table-bordered table-sm">
                              <tbody>
                                <tr>
                                  <td className="text-right font-weight-bold">{t("Sum")+": "}</td>
                                  <td>{sum+" kg"}</td>
                                </tr>
                              </tbody>  
                            </table>
                          </div>
                        </Row>
                  </Tab.Pane>
                    <Tab.Pane eventKey="driver">
                      <div className="col pt-1 pb-1">
                        <DriverForm editWeighingStart={this.props.editWeighingStart} t={t} weighing={weighing} updateDriver={this.props.updateDriver}/>
                        <InfoForm editWeighingStart={this.props.editWeighingStart} t={t} weighing={weighing} updateInfoInWeighing={this.props.updateInfoInWeighing}/>
                      </div>
                    </Tab.Pane>
                </Tab.Content>
              </Card.Body>
              <Card.Footer style={{'backgroundColor': 'white'}}>
              <div className="row m-1">
                      <div className="col text-left">
                        {!disablePass &&
                      <Button disabled={disablePass} onClick={this.handleModalShow} className="pb-1 nac-link btn btn-info btn-sm text-light">{disablePass && t("Passed")}{!disablePass && t("Pass")}</Button>
                        }
                      {!(finished || !single) &&
                          <Button onClick={this.handleFinishClick} className="ml-1 nac-link btn btn-warning btn-sm text-light">{t("Finish")}</Button>
                        }
                        {finished && single &&
                          <Button onClick={this.handleRenewClick} className="ml-1 nac-link btn btn-success btn-sm text-light">{t("Renew")}</Button>
                        }
                        {!finished && single &&
                          <Button onClick={this.handleChangePlateModalShow} className="ml-1 nac-link btn btn-success btn-sm text-light">{t("Change plate")}</Button>
                        }
                        {(finished || !single) && <Pdf weighing={weighing} customer={this.props.customer.customer} />}
                      </div>
                      <div className="col text-right">
                        
                        {this.props.weighing.difference !== 0 &&
                        <span className={weighingDifferenceRedColor ? 'ml-1 badge badge-danger' : 'ml-1 badge badge-success'}>
                        {" "}{this.props.weighing.difference < 0 ? "Too heavy":"Too light"}{": "}{Math.abs(this.props.weighing.difference)} kg
                        </span> 
                        }
                        <PassedComponent weighing={this.props.weighing} t={this.props.t} user={this.props.user} users={this.props.users} />
                        {" "}
                        
                        {!finished && single && <span className="badge badge-success ml-1">{t("New")}</span>}
                        {(!single || finished) && <span className="badge badge-secondary ml-l">{t("Finished")}</span>}
                        <span className="badge badge-info ml-1">
                          {this.props.weighing.alibi}
                        </span>
                      </div>
                    </div>
              </Card.Footer>
            </Tab.Container>
          </Card>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  user: state.authReducer.user,
  users: state.customersReducer.customer.customer === undefined ? [] : state.customersReducer.customer.customer.users,
  customer: state.customersReducer.customer,
  targets: state.targetsReducer.targets
});

export default {
  WeighingCard: connect(mapStateToProps, { updateInfoInWeighing,editWeighingStart,passDifference, finishWeighing, renewWeighing, updatePlateInWeighing, updateDriver,addTarget, deleteTarget, getTargets, updateTargetInWeighing, updateExternalDocsInWeighing})(withTranslation()(WeighingCard)),
  DriverForm: connect(mapStateToProps, {updateDriver})(withTranslation()(DriverForm)),
  TargetForm: connect(mapStateToProps, {getTargets, updateTargetInWeighing})(withTranslation()(TargetForm))
}
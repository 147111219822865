import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getDevices} from "../../actions/devices";
import {mqttConnect,mqttDisconnect,mqttSubscribe} from "../../actions/mqtt";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {Form} from "../vehicles/Form";

export class Weighing extends Component {

    state = {
        selectedDevice: {
            id: '',
            serial_number: '',
            api_key: '',
            application: ''
        },
        mqttClient: {},
        mqttMessage: {},
    };

    static propTypes = {
        getDevices: PropTypes.func.isRequired,
        mqttConnect: PropTypes.func.isRequired,
        devices: PropTypes.array.isRequired,
        mqttSubscribe: PropTypes.func.isRequired
    };
    onChange = e => this.setState({
        selectedDevice: {
            ...this.state.selectedDevice,
            [e.target.name]:e.target.value
        }
    });


    componentDidMount() {
        this.props.getDevices();
        this.props.mqttConnect("mr2j0vvhki6a39.messaging.solace.cloud", 20169, "clientId");
        //if (this.state.selectedDevice.id !== '') {
        //    this.props.mqttSubscribe("devices/"+this.state.selectedDevice.api_key+"/data")
        //}
        //this.interval = setInterval(this.props.mqttSubscribe("anpr_test"), 1000);
    };


    componentWillUnmount() {
        this.props.mqttDisconnect();
    }

    /*static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.devices !== prevState.devices) {
            prevState.mqttClient.unsubscribe("devices/")
            return {selectedDevice: nextProps.devices[0]}
        }
    }*/
    selectDevice() {
        let device = this.props.devices[0];
        if (device !== undefined) {
            this.setState({
                selectedDevice: device
            });
        }
    }
    onDeviceClick = (device) => {
         this.props.mqttSubscribe(["devices/"+  device.api_key + "/data",]);
         this.setState({
            selectedDevice: device
        });
    };
    onClickZero = () => {
        console.log(this.state.selectedDevice);
        this.props.mqttClient.send("devices/"+  this.state.selectedDevice.api_key + "/cmd","zero")
    };
    render() {
        const { t } = this.props;
        const { application } = this.state.selectedDevice;
        return (
                <div className="card card-body mt-4 mb-4 px-5">
                    <div className="row">
                        <div className="col-md-2 text-left mt-2">{t("Device")}:</div>
                        <div className="col-md-2 mt-2">{application.name}</div>
                        <div className="col-md-2 mt-1">
                            <DropdownButton className="wx-100" id="dropdown-basic-button" title={t("Select Device")}>
                                {this.props.devices.map(device => (
                                    <Dropdown.Item key={device.id} href="#" onClick={this.onDeviceClick.bind(this, device)}>{device.application.name}</Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </div>
                        <div className="col-md-4 text-right mt-1">
                            <h3>{this.props.mqttMessage.payloadString === undefined ? '0 kg' : this.props.mqttMessage.payloadString + ' kg'}</h3>
                        </div>
                        <div className="col-md-2 mt-1">
                            <button onClick={this.onClickZero} className="nac-link btn btn-info text-light">{t("Zero")}</button>
                        </div>
                    </div>
                </div>
        )
    }
}
const mapStateToProps = state => ({
    devices: state.devicesReducer.devices,
    mqttClient: state.mqttReducer.mqttClient,
    mqttMessage: state.mqttReducer.message,
    selectedDevice: state.selectDevice,
});
export default connect(mapStateToProps, { getDevices , mqttConnect, mqttDisconnect, mqttSubscribe})(withTranslation()(Weighing));

import axios from 'axios';

import {GET_VEHICLES, DELETE_VEHICLE, ADD_VEHICLE, UPDATE_VEHICLE} from "./types";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig} from "./auth";

//require('axios-debug')(axios);

// GET VEHICLES
export const getVehicles = () => (dispatch, getState) => {
    axios.get('/api/v1/vehicles/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_VEHICLES,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
};

// DELETE VEHICLE
export const deleteVehicle = (id) => (dispatch, getState) => {
    axios.delete(`/api/v1/vehicles/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({vehicleDeleted: "Vehicle Deleted"}))
            dispatch({
                type: DELETE_VEHICLE,
                payload: id
            });
        }).catch(err => console.log(err));
};

// ADD VEHICLE
export const addVehicle = vehicle => (dispatch, getState) => {
    axios.post("/api/v1/vehicles/", vehicle, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({vehicleAdded: "Vehicle Added"}));
            dispatch({
                type: ADD_VEHICLE,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
};
// UPDATE VEHICLE
export const updateVehicle = (vehicle) => (dispatch, getState) => {
    axios.put(`/api/v1/vehicles/${vehicle.id}/`, vehicle,tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_VEHICLE,
                payload: res.data
            });
        }).catch(err => console.log(err));
};
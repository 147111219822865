import { GET_CUSTOMERS } from "../actions/types.js";
import { ADD_CUSTOMER, DELETE_CUSTOMER, UPDATE_CUSTOMER, SET_CUSTOMER } from "../actions/types";

const initialState = {
    customers: [],
    customer: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            };
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.vehicles.filter(customers => customer.id !== action.payload)
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                customers: [...state.customers, action.payload]
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                customers: [...state.customers.map(customer => {
                    return customer.id === action.payload.id ? action.payload : customer;
                })],
            };
        case SET_CUSTOMER:
            //console.log(action.payload);
            return {
                ...state,
                customer: action.payload
            };
        default:
            return state;
    }
}
import { MQTT_CONNECTED } from "../actions/types.js";
import {MQTT_ARRIVED, MQTT_DISCONNECTED, MQTT_SUBSCRIBED, MQTT_UNSUBSCRIBED} from "../actions/types";

const initialState = {
    message: {},
    error: {},
    subscribed: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MQTT_CONNECTED:
            return {
                ...state,
                mqttClient: action.payload,
            };
        case MQTT_ARRIVED:
            return {
                ...state,
                message: action.payload
            };
        case MQTT_DISCONNECTED:
        return {
            ...state,
            mqttClient: null,
            message: {},
            error: action.payload
        };
        case MQTT_SUBSCRIBED:
            return {
              ...state,
              subscribed: action.payload
            };
        case MQTT_UNSUBSCRIBED:
        return {
            ...state,
            subscribed: ''
        };
        default:
            return state;
    }
}
import React, { PureComponent, Component, Fragment } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { selectFilter , textFilter } from 'react-bootstrap-table2-filter'
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getTransits, editTransitStart } from "../../actions/transits"
import { updateTargetInWeighing , updateDriver, updateInfoInWeighing} from "../../actions/weighings"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

const products = [ {id: 0, name: "test", price: 100} ];



function copyStyles(sourceDoc, targetDoc) {
  Array.from(sourceDoc.styleSheets).filter(
    (styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
  ).forEach(styleSheet => {
    if (styleSheet.cssRules) { // true for inline styles
      const newStyleEl = targetDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // true for stylesheets loaded from a URL
      const newLinkEl = targetDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

const RemoteCellEdit = (props) => {
  const cellEdit = {
    mode: 'click',
    errorMessage: props.errorMessage,
    blurToSave: true,
    onStartEdit: (row, column, rowIndex, columnIndex) => {
      props.editTransitStart();
      console.log("EDITING")},
  };
  return (
    <div>
      <BootstrapTable 
        remote={ { cellEdit: true } }
        keyField='id'
        data={ props.data }
        columns={ props.columns }
        cellEdit={ props.allowEdit ? cellEditFactory(cellEdit) : false }
        onTableChange={ props.onTableChange }
        striped
        hever
        condensed
        bootstrap4
        loading={ true }
        filter={ filterFactory() }
        sort= {{
          dataField: 'created',
          order: 'desc',
        }}
        
      />
    </div>
  );
}

  
export class TransitPortal extends PureComponent {
    containerEl = null;
    externalWindow = null;

    componentDidMount() {
        // STEP 1: Create a new window, a div, and append it to the window. The div 
        // *MUST** be created by the window it is to be appended to (Edge only)
        this.externalWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
        this.containerEl = this.externalWindow.document.createElement('div');
        this.externalWindow.document.body.appendChild(this.containerEl);

        copyStyles(document, this.externalWindow.document);

        this.externalWindow.addEventListener('beforeunload', 
          this.props.closeWindowPortal());
      }
    componentWillUnmount() {
        // STEP 2: This will fire when this.state.showWindowPortal in the parent component
        // becomes false so we tidy up by just closing the window
        this.externalWindow.close();
    }
    render() {
        // STEP 3: The first render occurs before componentDidMount (where we open the
        // new window) so container may be null, in this case render nothing.
        if (!this.containerEl) {
          return null;
        } 
        
        // STEP 4: Append props.children to the container <div> in the new window
        return ReactDOM.createPortal(this.props.children, this.containerEl);  
      }
}

export class TransitList extends Component {
  state = {
    transits: this.props.transits,
    showTransit: false,
    errorMessage: null
  }
  
  componentDidMount() {
    window.addEventListener('beforeunload', () => {
      this.closeWindowPortal();
    });
    this.props.getTransits()
    this.updateTransits()
  }
  componentWillUnmount() {
    this.closeWindowPortal();
  }
  updateTransitsNow = async (now) => {
    await this.props.getTransits()
    this.updateTimeout = setTimeout(this.props.getTransits(), now);
  }
  updateTransits = async () => {
    await this.props.getTransits()
    this.updateTimeout = setTimeout(this.updateTransits, 20000);
  };
  toggleShowTransit = () => {
    this.setState(state => ({
      ...this.state,
      showTransit: !state.showTransit,
    }));
  }
  closeWindowPortal = () => {
    this.setState({
      ...this.state,
      showTansit: false })
  }
  handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
    if (dataField === "info") {
      this.props.updateInfoInWeighing(rowId, newValue)
    }
    if (dataField === "target") {
      console.log(data, rowId, newValue)
      if (newValue) {
        this.props.updateTargetInWeighing(rowId, parseInt(newValue))
      }
    }
    if (dataField === "driver.phone_number") {
      var drv = data.filter(transit => transit.id === rowId)[0].driver

      if (!drv) {
        var driver = {
          phone_number: newValue
        }
      } else {
        var driver = {
          id: data.filter(transit => transit.id === rowId)[0].driver.id,
          phone_number: newValue
        }  
      }
      this.props.updateDriver(rowId, driver)
    }
    this.props.fetchMoreData(true); 
  }
  render() {
    const { t } = this.props

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        editable: false,
        hidden: true
      },
      {
      dataField: 'plate',
      text: 'Plate number',
      editable: false,
      sort: true,
      filter: textFilter(),
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.onSearchPlateChange({target: {value: row.plate}})
        }
      }
    }, {
      dataField: 'driver.phone_number',
      text: 'Phone number'

    },
    {
      dataField: 'info',
      text: 'Info'
    },
    {
      dataField: 'target',
      text: 'Target',
      formatter: ( cell, row ) => {
          //console.log(cell, row)
          if (cell) {
            var target = this.props.targets.find(t => t.id == cell)
            if (target) {
              return target.short_name
            }
          }
      },
      editor: {
        type: Type.SELECT,
        getOptions: () => this.props.targets.map(target => {
          return {
          value: target.id,
          label: target.short_name
          }
        })
      },
      filter: selectFilter({
        options: () => this.props.targets.map(target => {
          return {
          value: target.id,
          label: target.short_name
          }
        })
      })
    }, {
      dataField: 'created',
      text: 'Date',
      formatter: (cell) => {
        let dateObj = cell;
        if (typeof cell !== 'object') {
          dateObj = new Date(cell);
        }
        return `${(dateObj.getHours())}:${(dateObj.getMinutes())} ${('0' + dateObj.getDate()).slice(-2)}.${('0' + (dateObj.getMonth() + 1)).slice(-2)}.${dateObj.getFullYear()}`;
      },
      sort: true
    }
  ];
    
    return (
      <Fragment>
        <a className="nav-item nav-link" href="#" onClick={this.toggleShowTransit}>{t("Transit")}</a>
        {this.state.showTransit &&
          <TransitPortal closeWindowPortal={this.closeWindowPortal}>
            <RemoteCellEdit
              handleRedirect={this.props.handleRedirect}
              columns={columns}
              data={ this.props.transits }
              errorMessage={ this.state.errorMessage }
              onTableChange={ this.handleTableChange }
              allowEdit={this.props.customer.allowTransitListEdit}
              editTransitStart={this.props.editTransitStart}
              fetchMoreData={this.props.fetchMoreData}
            />
          </TransitPortal>
        }
      </Fragment>
    )
  }
}
const mapStateToProps = state => ({
  transits: state.transitsReducer.transits,
  targets: state.targetsReducer.targets,
  customer: state.customersReducer.customer
})

export default connect(mapStateToProps, {updateInfoInWeighing, getTransits, updateTargetInWeighing, updateDriver, editTransitStart})(withTranslation()(TransitList))
import React, {Component, Fragment, Suspense} from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";



import Header from './layout/Header';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import VehicleDashboard from './vehicles/Dashboard';
import CustomerDashboard from './customers/Dashboard';
import WeighingDashboard from './weighings/Dashboard';
import SettingsDashboard from './settings/Dashboard';
import AnprDashboard from './anpr/Anprs';
import Alerts from './layout/Alerts';
import Login from './accounts/Login';
import Register from "./accounts/Register";
import PrivateRoute from "./common/PrivateRoute";
import {Container} from 'react-bootstrap'
import { Provider } from 'react-redux';
import store from '../store';

import {loadUser} from '../actions/auth';

// Alert options
const alertOptions = {
    timeout: 3000,
    position: 'bottom center'
};

class App extends Component {
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return (
            <Suspense fallback="loading">
                <Provider store={store}>
                    <AlertProvider template={AlertTemplate} {...alertOptions}>
                            <Router>
                                <Fragment>
                                    <Header />
                                    <Alerts />
                                    <Container fluid style={{position:'relative', top:'95px'}}>
                                        <Switch>
                                            <PrivateRoute exact path="/" component={WeighingDashboard}/>
                                            <PrivateRoute exact path="/vehicles" component={VehicleDashboard}/>
                                            <PrivateRoute exact path="/customers" component={CustomerDashboard}/>
                                            <PrivateRoute exact path="/anprs" component={AnprDashboard}/>
                                            <PrivateRoute exact path="/weighing" component={WeighingDashboard}/>
                                            <PrivateRoute exact path="/application_settings" component={SettingsDashboard}/> 
                                            <Route exact path="/login" component={Login}/>
                                        </Switch>
                                    </Container>
                                </Fragment>
                            </Router>
                    </AlertProvider>
                </Provider>
            </Suspense>

        )
    }
}

export default App;
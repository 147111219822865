import { combineReducers } from 'redux';
import vehicles from './vehicles';
import companies from './companies';
import customers from './customers';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import version from './version';
import anpr from './anprs';
import mqtt from "./mqtt";
import devices from "./devices";
import weighings from "./weighings";
import targets from "./targets";
import transits from "./transits";
export default combineReducers({
    vehiclesReducer: vehicles,
    companiesReducer: companies,
    customersReducer: customers,
    errorsReducer: errors,
    messagesReducer: messages,
    authReducer: auth,
    versionReducer: version,
    anprReducer: anpr,
    mqttReducer: mqtt,
    devicesReducer: devices,
    weighingsReducer: weighings,
    targetsReducer: targets,
    transitsReducer: transits,
});

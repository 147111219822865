import React, { Component, Fragment} from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export class Alerts extends Component {
    static propTypes = {
        error: PropTypes.object.isRequired,
        message: PropTypes.object.isRequired
    };
    componentDidUpdate(prevProps) {
        //console.log(this.props);
        const { error, alert, message } = this.props;
        if (error !== prevProps.error) {
            //alert.error(`Error: ${error.msg.detail} Status: ${error.status}`);
            if (error.msg.plate) {alert.error(`Plate: ${error.msg.plate.join()}`);}
            if (error.msg.customer) {alert.error(`Customer: ${error.msg.customer.join()}`);}
            if (error.msg.non_field_errors) {alert.error(error.msg.non_field_errors.join());}
            if (error.msg.username) {alert.error(error.msg.username.join());}

        }
        if (message !== prevProps.message) {
            if (message.vehicleDeleted) { alert.success(message.vehicleDeleted);}
            if (message.vehicleAdded) { alert.success(message.vehicleAdded);}
            if (message.passwordsNotMatch) { alert.error(message.passwordsNotMatch);}
        }

    }

    render() {
        return <Fragment />
    }
}

const mapStateToProps = state => ({
    error: state.errorsReducer,
    message: state.messagesReducer
});
export default connect(mapStateToProps)(withAlert()(Alerts));
import axios from 'axios';

import {GET_TARGETS, DELETE_TARGET, ADD_TARGET, UPDATE_TARGET} from "./types";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig} from "./auth";

//require('axios-debug')(axios);

// GET VEHICLES
export const getTargets = () => (dispatch, getState) => {
    axios.get('/api/v1/weighing-targets/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_TARGETS,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
};

// DELETE VEHICLE
export const deleteTarget = (id) => (dispatch, getState) => {
    axios.delete(`/api/v1/weighing-targets/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({targetDeleted: "Target Deleted"}))
            dispatch({
                type: DELETE_TARGET,
                payload: id
            });
        }).catch(err => console.log(err));
};

// ADD VEHICLE
export const addTarget = target => (dispatch, getState) => {
    axios.post("/api/v1/weighing-targets/", target, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({targetAdded: "Target Added"}));
            dispatch({
                type: ADD_TARGET,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
};
// UPDATE VEHICLE
export const updateTarget = (target) => (dispatch, getState) => {
    axios.put(`/api/v1/weighing-targets/${target.id}/`, target,tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_TARGET,
                payload: res.data
            });
        }).catch(err => console.log(err));
};
import {createStore, applyMiddleware, bindActionCreators} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import mqtt from 'redux-mqtt';
import Paho from 'paho-mqtt';
import {MQTT_ARIVED} from "./actions/types";
import { arrived } from "./actions/mqtt";
import reduxMqttMiddleware from "./middleware";
/*
import { getAnprs } from "./actions/anprs";

const updateAnprsMiddleware = ({ dispatch }) => next => {
  const updateAnprs = async () => {
    await getAnprs()(dispatch);
    setTimeout(updateAnprs, 1000);
  };

  updateAnprs();

  return action => next(action);
};
*/




const initialState = {};

const MQTT_CONNECT = ()=>{
    return {
        mqtt: (client, dispatch, getState) =>{
            // client is mqtt client object passed via middleware to store
            return client
        }
    }
};


const middleware = [
    thunk,
    reduxMqttMiddleware('ws://test.mosquitto.org:8080')
    ];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware,))
);


export default store;
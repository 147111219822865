import { GET_WEIGHINGS, LOADING_WEIGHINGS, UPDATE_DRIVER_IN_WEIGHING, UPDATE_WEIGHING_IN_PROGRESS} from "../actions/types.js";
import {PASS_DIFFERENCE, UPDATE_PLATE_IN_WEIGHING, UPDATE_TARGET_IN_WEIGHING, UPDATE_EXTERNAL_DOCS_IN_WEIGHING, UPDATE_INFO_IN_WEIGHING} from "../actions/types";


const initialState = {
    weighings: [],
    transits: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_WEIGHINGS:

            return {
                ...state,
                loading: true
            };
        case GET_WEIGHINGS:
            console.log(action.updateWeighings)
            if (!action.updateWeighings) {
                return {
                    ...state,
                    weighings: action.payload.data,
                    count: action.payload.count,
                    loading: false,
                    update: false,
                };
            }
            /*
        case UPDATE_VEHICLE:

            return {
                ...state,
                vehicles: [...state.vehicles.map(vehicle => {
                    return vehicle.id === action.payload.id ? action.payload : vehicle;
                })],
            };
            */
        case PASS_DIFFERENCE:
            return {
                ...state,
                weighings: [...state.weighings.map(weighing => {
                    return weighing.id === action.payload.id ? action.payload : weighing;
                })]
            };
        case UPDATE_PLATE_IN_WEIGHING:
            return state;
        case UPDATE_TARGET_IN_WEIGHING:
            return {
                ...state,
                weighings: [...state.weighings.map(weighing => {
                    return weighing.id === action.payload.id ? action.payload : weighing;
                })],
                update: false
            }
        case UPDATE_EXTERNAL_DOCS_IN_WEIGHING:
                return {
                    ...state,
                    weighings: [...state.weighings.map(weighing => {
                        return weighing.id === action.payload.id ? action.payload : weighing;
                    })],
                    update: false
                }
        case UPDATE_DRIVER_IN_WEIGHING:
            return {
                ...state,
                weighings: [...state.weighings.map(weighing => {
                    return weighing.id === action.payload.id ? action.payload : weighing;
                })],
                update: false
            }
        case UPDATE_WEIGHING_IN_PROGRESS:
            return {
                ...state,
                update: action.payload
            }
        case UPDATE_INFO_IN_WEIGHING:
            return {
                ...state,
                weighings: [...state.weighings.map(weighing => {
                    return weighing.id === action.payload.id ? action.payload : weighing;
                })],
                update: false
            }

        default:
            return state;
    }
}
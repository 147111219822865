import { GET_VEHICLES, DELETE_VEHICLE, ADD_VEHICLE, UPDATE_VEHICLE } from "../actions/types.js";

const initialState = {
    vehicles: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload
            };
        case DELETE_VEHICLE:
            return {
                ...state,
                vehicles: state.vehicles.filter(vehicle => vehicle.id !== action.payload)
            };
        case ADD_VEHICLE:
            return {
                ...state,
                vehicles: [...state.vehicles, action.payload]
            };
        case UPDATE_VEHICLE:
            return {
                ...state,
                vehicles: [...state.vehicles.map(vehicle => {
                    return vehicle.id === action.payload.id ? action.payload : vehicle;
                })],
            };
        default:
            return state;
    }
}
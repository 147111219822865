import React, { Fragment } from 'react'
import Vehicles from './Vehicles';


export default function Dashboard() {
    return (
        <Fragment>
            <div className="container mt-5">
                <Vehicles />
            </div>
        </Fragment>
    )
}

import axios from 'axios';

import {UPDATE_SETTING} from "./types";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig} from "./auth";
import { getCustomers } from "./customers";

// UPDATE USER
export const updateSetting = (setting) => (dispatch, getState) => {
    axios.patch(`/api/v1/application-settings/${setting.id}/`, setting,tokenConfig(getState))
        .then(res => {
            dispatch(getCustomers());
        }).catch(err => console.log(err));
};
import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCustomers , addCustomer } from '../../actions/customers';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import Paho from "paho-mqtt";

export class Form extends Component {

    state = {
        selectedCustomer: {
            id: '',
            vat_id: '',
            name_long: '',
            name_short: '',
            description: '',
            address: {},
            parent_maintainer: '',
        },
    };

    static propTypes = {
        addCustomer: PropTypes.func.isRequired,
        getCustomers: PropTypes.func.isRequired,
        customers: PropTypes.array.isRequired,
    };
    onChange = e => this.setState({
        selectedCustomer: {
            ...this.state.selectedCustomer,
            [e.target.name]:e.target.value
        }
    });
    onSubmit = e => {
        e.preventDefault();
        const { id, vat_id, name_long, name_short, description, address } = this.state.selectedCustomer;
        //console.log(id, vat_id,name_long,name_short, description, address.street, address);
        //const cid = customer.id;
        const customer = { id, name_short, name_long, address: address.id, description};
        //console.log(customer);
        //if (vehicle.id) {
        //    this.props.updateVehicle(vehicle);
        //} else {
        //    this.props.addVehicle(vehicle);
        //}
        this.setState({
            selectedCustomer: {
                id: '',
                name_long: '',
                name_short: '',
                description: '',
                address: {},
            }
        })
    }
    onCustomerClick = (customer) => {
        this.setState({
            selectedCustomer: {
                ...this.state.selectedCustomer,
                customer: customer
            }
        });
        //console.log(this.state);
    }

    componentDidMount() {
        this.props.getCustomers();
    }


    render() {
        const { name_short, name_long } = this.state.selectedCustomer;
        return (
            <div className="card card-body mt-4 mb-4 px-5">
                <h2>Add Vehicle</h2>
                <form onSubmit={this.onSubmit}>
                    <div className="row mt-2">
                        <div className="col-md-2 text-right mt-2">Full name:</div>
                        <div className="col-md-7 mt-1"><input className="form-control w-100 mx-3" type="text" value={name_long} name="name_long" onChange={this.onChange} /></div>
                        <div className="col-md-3 text-right pr-0 mt-1">

                        </div>
                    </div>
                    <div className="row mt-2">
                        <label className="col-md-2 text-right mt-2">Plate:</label>
                        <div className="col-md-4">
                            <input
                                className="form-control w-100 mx-3 mt-1"
                                type="text"
                                name="plate"
                                onChange={this.onChange}

                            />
                        </div>
                        <label className="col-md-3 text-right mt-2">Access permission:</label>

                    </div>


                    <div className="row mt-2">
                        <div className="col-12 mt-5">
                            <Button variant="success" weight="1000px" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    customers: state.customersReducer.customers,
    mqttClient: state.mqttReducer,
});
export default connect(mapStateToProps, { getCustomers, addCustomer })(Form);

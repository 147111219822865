import React, { Fragment } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Image, Font,pdf } from '@react-pdf/renderer';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Button from 'react-bootstrap/Button'
import { saveAs } from 'file-saver';

class Header extends React.Component {
  static propTypes = {
    customer: PropTypes.object.isRequired
  };
  render() {
    const { customer } = this.props
    return (
      <View style={styles.header} fixed>
        <View style={styles.headerleft}>
          <Text>{customer.name_long}</Text>
        </View>
        <View style={styles.headerright}>
          <Text>{moment().format("DD-MM-YYYY hh:mm:ss")}</Text>
        </View>
      </View>
    )
  }
}

class Title extends React.Component {
  static propTypes = {
    weighing: PropTypes.object.isRequired
  }
  render() {
    const {t,weighing} = this.props
    return (
      <View style={styles.title}>
        <Text>{t("Weighing ticket")}</Text>
        <Text style={{fontSize: 20}}>{t("Vehicle")}: {weighing.plate}</Text>
      </View>
    )
  }
}

class Weighing extends React.Component {
  render() {
    const { t } = this.props
    return (
      <View style={{marginTop: 20}}>
        <View style={styles.header}>
          <Text style={styles.headerleft}>{this.props.name}</Text>
          <Text style={styles.headerright}>ID: {this.props.alibi}</Text>
        </View>
        
        <View style={styles.container}>
          <View style={styles.leftColumn}>
              <Image
              src={this.props.weighing.images[0] !== undefined ? this.props.weighing.images[0].image.replace('http', 'https') : ''}
              style={styles.image}
            />
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.content}>
              <View style={styles.contentleft}><Text>{t("Mass")}:</Text></View>
              <View style={styles.contentright}><Text>{this.props.weight} kg</Text></View>
            </View>
            <View style={styles.content}>
              <View style={styles.contentleft}><Text>{t("Date")}:</Text></View>
              <View style={styles.contentright}><Text>{ moment(this.props.date).tz('Europe/Warsaw').format("DD-MM-YYYY HH:mm")}</Text></View>
            </View>
          </View>
          
        </View>
      </View>
    )
  }
}

class WeighingTicket extends React.Component {
  static propTypes = {
    weighing: PropTypes.object.isRequired
  }
  
  render() {
    const { t, weighing } = this.props
    const single = weighing.next_weighing === null
    return (
      <Document>
        <Page style={styles.body}>
          <Header {...this.props}/>
          <Title {...this.props}/>
          <Weighing {...this.props} weighing={weighing} name={single ? t("Single weighing") : t("First weighing")} alibi={weighing.alibi} weight={weighing.weight} date={weighing.created}/>
          {!single && <Weighing {...this.props} weighing={weighing.next_weighing} name={t("Second weighing")} alibi={weighing.next_weighing.alibi} weight={weighing.next_weighing.weight} date={weighing.next_weighing.created}/>}
          {!single && <View style={styles.summary}>
            <Text style={{borderBottom: 1}}>{t("Summary")}</Text>
            <Text>{t("Netto mass")}: {weighing.weight - weighing.next_weighing.weight} kg</Text>
          </View>}
        </Page>
      </Document>

    )
  }
}

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf"
});

const styles = StyleSheet.create({
  summary: {
    paddingTop: 50,
  },
body: {
  paddingTop: 35,
  paddingBottom: 65,
  paddingHorizontal: 35,
  fontFamily: 'Roboto'
},
title: {
  marginTop: 40,
  marginBottom: 20,
  fontSize: 24,
  textAlign: 'center',
},
author: {
  fontSize: 12,
  textAlign: 'center',
  marginBottom: 40,
},
subtitle: {
  fontSize: 18,
  margin: 12,
  fontFamily: 'Oswald'
},
text: {
  margin: 12,
  fontSize: 14,
  textAlign: 'justify',
  fontFamily: 'Times-Roman'
},
image: {
   marginBottom: 10,
},
header: {
  flexDirection: 'row',
  alignItems: 'stretch',
  fontSize: 12,
  color: 'grey',
  borderBottomWidth: 1,
},
headerleft: {
  flexDirection: 'column',
  flexGrow: 9,
  textTransform: 'uppercase',
},
headerright: {
  alignSelf: 'flex-end',
  justifySelf: 'flex-end',
},
pageNumber: {
  position: 'absolute',
  fontSize: 12,
  bottom: 30,
  left: 0,
  right: 0,
  textAlign: 'center',
  color: 'grey',
},
container: {
  flex: 1,
  flexDirection: 'row',
},
leftColumn: {
  flexDirection: 'column',
  width: 170,
  paddingTop: 10,
  paddingRight: 15,
},
rightColumn: {
  flexDirection: 'column',
  width: 370,
  paddingTop: 10,
  paddingRight: 15,
},
content: {
  flexDirection: 'row',
  marginBottom: 10,
},
contentleft: {
  flexDirection: 'column',
  flexGrow: 9,
},
contentright: {
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'flex-end',
  justifySelf: 'flex-end',
}
});
const generatePDFDocument = async (filename,props) => {
  const blobPdf = await pdf(<WeighingTicket {...props}/>);
  blobPdf.updateContainer(<WeighingTicket {...props}/>);
  const result = await blobPdf.toBlob();
  console.log(props)
  saveAs(result, filename);
};
class Pdf extends React.Component {
  static propTypes = {
    weighing: PropTypes.object.isRequired,
  }
  state ={
    pdfReady: false
  }

  render() {
    const { weighing, t, customer } = this.props
    const filename = weighing.plate + "_" + moment(weighing.created).tz('Europe/Warsaw').format("DD-MM-YYYY_HH-mm") + ".pdf"
    
    return (
      <button className="ml-1 nac-link btn btn-danger btn-sm text-light"
        onClick={async () => { await generatePDFDocument(filename,this.props)}}
      >{t("Ticket")}</button>
    )

  }
}

export default connect()(withTranslation()(Pdf));
import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import { Col, Row, Form, FormLabel, FormControl, Button } from 'react-bootstrap';
import { updateSetting } from "../../actions/settings"

function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

function areTheseObjectsEqual(first, second) {
    const al = Object.getOwnPropertyNames(first);
    const bl = Object.getOwnPropertyNames(second);
    console.log(first)
    console.log(second)
    // Check if the two list of keys are the same
    // length. If they are not, we know the objects
    // are not equal.
    if (al.length !== bl.length) return false;
    
    // Check that all keys from both objects match
    // are present on both objects.
    const hasAllKeys = al.every(value => !!bl.find(v => v === value));
  
    // If not all the keys match, we know the
    // objects are not equal.
    if (!hasAllKeys) return false;
    
    // We can now check that the value of each
    // key matches its corresponding key in the
    // other object.
    for (const key of al) {
        if (Array.isArray(first[key])) {
            if (!arrayEquals(first[key], second[key])) return false  
        } else  {
            if (first[key] !== second[key]) return false
        }
    };
    // If the object hasn't return yet, at this
    // point we know that the objects are the
    // same
    return true;
  }

export class ApplicationSettings extends Component {

    state = {
        applications: []
    };
    onSettingChange = (event) => {
        const settingid = event.target.getAttribute("settingid");
        const value = event.target.value;
        const type = event.target.type; 
        const checked = event.target.checked; 
        console.log(checked);
        type === "checkbox" ? 
            this.setState(prevState => ({
                ...this.state,
                applications: prevState.applications.map(application => ({
                    ...application,
                    settings: application.settings.map(setting => setting.id == settingid ? {...setting, settingValue: String(checked)}: setting)
                }))
            }))
            :
            this.setState(prevState => ({
                ...this.state,
                applications: prevState.applications.map(application => ({
                    ...application,
                    settings: application.settings.map(setting => setting.id == settingid ? {...setting, settingValue: value}: setting)
                }))
            }))
    }
    
    componentDidMount() {
        this.setState({
            ...this.state,
            applications: [...this.props.customer.applications]
        })
    }
    onSaveSetting(setting, event) {
        console.log(setting)
        this.props.updateSetting(setting)
    }
    render() {
        const applications = this.state.applications
        // console.log(applications)
        const { t } = this.props
        return (
            <Form>
                {applications.map((application, key) => (
                    <div key={key}>
                        <h3>
                            {application.name}
                        </h3>
                        {application.settings.map((setting, key) => (
                            <Row key={key} className="align-items-end pb-3">
                                <Col>
                                    <FormLabel column={1}>
                                        {t(setting.verboseSettingName)}
                                    </FormLabel>
                                    <FormControl onChange={this.onSettingChange} 
                                    type={setting.field_type == 1 ? "checkbox" 
                                        : setting.field_type == 3 ? "textarea" 
                                        : setting.field_type == 4 ? "number" 
                                        : "text"} 
                                    settingid={setting.id} 
                                    checked={setting.settingValue === "true"}
                                    value={setting.settingValue} />
                                </Col>
                                <Col xs={1}>
                                    {!areTheseObjectsEqual(this.props.customer.applications.find(app => app.id === application.id).settings.find(set => set.id === setting.id),setting) && 
                                    <Button onClick={this.onSaveSetting.bind(this, setting)}>{t("Save")}</Button>
                                }
                                </Col>
                            </Row>
                        ))}
                    </div>
                ))}
            </Form>
        )

    }
}    
const mapStateToProps = state => ({
    customer: state.customersReducer.customer.customer
});
export default connect(mapStateToProps,{updateSetting})(withTranslation()(ApplicationSettings));

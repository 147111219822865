import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getVehicles, deleteVehicle, updateVehicle } from "../../actions/vehicles";
import MUIDataTable from "mui-datatables";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Form from './Form';
import {withTranslation} from "react-i18next";

export class Vehicles extends Component {

    static propTypes = {
        vehicles: PropTypes.array.isRequired,
        getVehicles: PropTypes.func.isRequired,
        deleteVehicle: PropTypes.func.isRequired
    };

    state = {
        selectedVehicle: {
            id: '',
            plate: '',
            vehicle_permission: 1,
            customer: '',
            maximum_weight: '40001',
        },
        columns: [],
         tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
            searchText: '',
            filterList: [],
            columns: []
        },
    };
    handleChange = (action, tableState) => {
      //console.log("Table state changed || " + JSON.stringify(action));
      //console.log("CURRENT STATE: " + JSON.stringify(tableState));

      if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
        this.setState({
          tableStatePersist: {
            searchText: tableState.searchText,
            filterList: tableState.filterList, //An array of filters for all columns
            columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
          },
        });
      }
    };
    getColumns = () => {
        const { t } = this.props;
      //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
      let columns = [
            {
              name: "id",
              options: {
                  display: 'false'
              }
            },
            {
                name: "plate",
                label: t("Plate"),
                options: {},
            },
            {
                name: "maximum_weight",
                label: t("Max weight(kg)"),
                options: {},
            },
            {
                name: "vehicle_permission",
                label: t("Access perm."),
                options: {
                  filter: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                          <FormControlLabel
                              label = {value === 1 ? t("Allow") : t("Deny")}
                              value = {value === 1 ? t("Allow") : t("Deny")}
                              checked={value === 1}
                              control={
                                  <Switch color="primary" value={value === 1 ? t("Allow") : t("Deny")}/>
                              }
                              onChange={event => {
                                  const { id, vehicle_permission, plate, customer } = this.props.vehicles[tableMeta.rowIndex];
                                  const vehicle = { id, vehicle_permission: value === 1 ? 2 : 1 , plate, customer };
                                  this.props.updateVehicle(vehicle);
                              }}

                          />
                      )
                  }
              }
            }
        ];

      //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
      for(let i = 0; i < columns.length; i++) {
        //Assign the filter list to persist
        columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
        if(this.state.tableStatePersist.columns[i] !== undefined) {
          //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
          if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
            columns[i].options.display = this.state.tableStatePersist.columns[i].display;
          //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
          if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
            //The sortDirection prop only permits sortDirection for one column at a time
            if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
              columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
          }
        }
      }

      return columns;
    };
    updateTimeout;
    componentDidMount() {
        this.updateVehicles();
    }
    updateVehicles  = async () => {
      await this.props.getVehicles();
      this.updateTimeout = setTimeout(this.updateVehicles, 3000)
    };
    componentWillUnmount() {
        clearTimeout(this.updateTimeout);
    }
    handleRowClick = vehicle => {
        this.setState({
            selectedVehicle: vehicle
        });
    };
    getSearchText = () => {
      return this.state.tableStatePersist.searchText;
    };

    render() {
        return (
            <Fragment>
                <Form
                    {...this.state.selectedVehicle}
                />
                <MUIDataTable
                    columns={this.getColumns()}
                    data = {this.props.vehicles}
                    options = {{
                        searchText: this.getSearchText(),
                        onTableChange: (action, tableState) => this.handleChange(action, tableState),
                        responsive: 'scrollMaxHeight',
                        onRowsDelete: (rowsDeleted) => {
                            const idsToDelete = rowsDeleted.data.map(d => this.props.vehicles[d.dataIndex].id); // array of all ids to to be deleted
                            idsToDelete.map(deleted => this.props.deleteVehicle(deleted))
                        },
                        onRowClick: (rowData, rowMeta) => {
                            this.handleRowClick(this.props.vehicles[rowMeta.dataIndex]);
                        }
                    }}
                />
            </Fragment>

        );
    }
}

const mapStateToProps = state => ({
    vehicles: state.vehiclesReducer.vehicles
});

export default connect(mapStateToProps, { getVehicles, deleteVehicle, updateVehicle })(withTranslation()(Vehicles))
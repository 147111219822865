var pjson = require('../../../../package.json');
const initialState = {
    'version': pjson.version,
};

export default function (state = initialState, action) {
    switch(action.type) {
        default:
            return state;
    }
}
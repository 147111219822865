import { GET_TARGETS, DELETE_TARGET, ADD_TARGET, UPDATE_TARGET } from "../actions/types.js";

const initialState = {
    targets: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TARGETS:
            return {
                ...state,
                targets: action.payload
            };
        case DELETE_TARGET:
            return {
                ...state,
                targets: state.target.filter(target =>  target.id !== action.payload)
            };
        case ADD_TARGET:
            return {
                ...state,
                targets: [...state.targets, action.payload]
            };
        case UPDATE_TARGET:
            return {
                ...state,
                targets: [...state.targets.map(target => {
                    return target.id === action.payload.id ? action.payload : target;
                })],
            };
        default:
            return state;
    }
}
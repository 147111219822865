import axios from 'axios';
import { tokenConfig} from "./auth";

import {ADD_CUSTOMER, DELETE_CUSTOMER, GET_CUSTOMERS, SET_CUSTOMER, UPDATE_CUSTOMER} from "./types";
import {createMessage, returnErrors} from "./messages";

// GET CUSTOMERS
export const getCustomers = () => (dispatch, getState) => {
    axios.get('/api/v1/customers/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_CUSTOMERS,
                payload: res.data
            });
        }).catch(err => console.log(err));
};

export const deleteCustomer = (id) => (dispatch, getState) => {
    axios.delete(`/api/v1/customers/${id}/`, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({customerDeleted: "Customer Deleted"}))
            dispatch({
                type: DELETE_CUSTOMER,
                payload: id
            });
        }).catch(err => console.log(err));
};

export const addCustomer = customer => (dispatch, getState) => {
    axios.post("/api/v1/customers/", customer, tokenConfig(getState))
        .then(res => {
            dispatch(createMessage({customerAdded: "Customer Added"}));
            dispatch({
                type: ADD_CUSTOMER,
                payload: res.data
            });
        }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
};

export const updateCustomer = (customer) => (dispatch, getState) => {
    axios.put(`/api/v1/vehicles/${customer.id}/`, customer,tokenConfig(getState))
        .then(res => {
            dispatch({
                type: UPDATE_CUSTOMER,
                payload: res.data
            });
        }).catch(err => console.log(err));
};
function checkBoolOption(customer, option){
        if (customer === undefined) {
            return false
        }
        const settings = customer.settings;
        let menuVehicleActive;
        if (settings === undefined) {
            return false
        } else {
            let opt =settings.find(x=>x.settingName === option);
            if (opt=== undefined) {
                return false
            } else {
            return opt.settingValue === "1";
            }
        }
    }
function checkStringOption(customer, option) {
    if (customer === undefined) {
        return ''
    } 
    const settings = customer.settings;
        if (settings === undefined) {
            return ''
        }  else {
            let opt =settings.find(x=>x.settingName === option);
            if (opt=== undefined) {
                return ''
            } else {
            return opt.settingValue;
            }
        }
}
export const setCustomer = (customer) => (dispatch, getState) => {
    const menuVehiclesActive = checkBoolOption(customer,'VehiclesMenu');
    const menuCustomersActive = checkBoolOption(customer,'CustomersMenu');
    const menuAnprActive = checkBoolOption(customer,'AnprMenu');
    const menuWeighigActive = checkBoolOption(customer,'WeighingMenu');
    const onlineWeighing = checkBoolOption(customer,'OnlineWeighing');
    const driverEnabled = checkBoolOption(customer,'driverEnabled');
    const targetSelectionEnabled = checkBoolOption(customer,'targetSelectionEnabled')
    const allowTransitListEdit = checkBoolOption(customer, 'allowTransitListEdit')
    const allowDocumentsListEdit = checkBoolOption(customer, 'allowDocumentsListEdit')
    const docsTabAlwaysOn = checkBoolOption(customer, 'docsTabAlwaysOn')
    const sortWeighingsBy = checkStringOption(customer, 'sortWeighingsBy')

    dispatch({
        type: SET_CUSTOMER,
        payload: {
            customer: customer,
            menuVehiclesActive: menuVehiclesActive,
            menuCustomersActive: menuCustomersActive,
            menuAnprActive: menuAnprActive,
            menuWeighigActive: menuWeighigActive,
            onlineWeighing: onlineWeighing,
            driverEnabled: driverEnabled,
            targetSelectionEnabled: targetSelectionEnabled,
            allowTransitListEdit: allowTransitListEdit,
            allowDocumentsListEdit: allowDocumentsListEdit,
            docsTabAlwaysOn: docsTabAlwaysOn,
            sortWeighingsBy: sortWeighingsBy,
        }
    })
};
import React, { Component, Fragment } from 'react'
import { Link, Redirect, useHistory  } from "react-router-dom";
import { connect } from 'react-redux';
import PropTypes, { element } from 'prop-types'
import { logoutUser } from "../../actions/auth";
import { withTranslation } from 'react-i18next';
import { getCustomers, setCustomer } from "../../actions/customers";
import Form from 'react-bootstrap/Form'
import TransitList from '../transit/Transit'

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export class Header extends Component {
    static propTypes = {
        customers: PropTypes.array.isRequired,
        auth: PropTypes.object.isRequired,
        logoutUser: PropTypes.func.isRequired,
        message: PropTypes.object.isRequired,
        getCustomers: PropTypes.func.isRequired,
        setCustomer: PropTypes.func.isRequired,
        //customer: PropTypes.array.isRequired,
    };
    state = {
        selectedCustomer: {},
        Customer: {},
        maintainer_logo: '',
        customer_logo: '',
    };

    componentDidMount() {
        this.props.getCustomers();
        if (this.props.customer.customer !== undefined) {
            this.setState({
                ...state,
                maintainer_logo: this.props.customer.customer.parent_maintainer.logo,
                customer_logo: this.props.customer.customer.logo

            })
        }

    };
    static getDerivedStateFromProps(props, state) {
        let id;
        let maintainer_logo;
        let customer_logo;
        let customer = props.customers[0];
        if (customer !== undefined) {
            maintainer_logo = customer.parent_maintainer.logo;
            customer_logo = customer.logo;
        }
        if (props.customers[0] === undefined) {
            props.getCustomers();
            id = 0;
        } else {
            id = props.customers[0].id;
        }
        if (state.customers !== props.customers) {
            
            props.setCustomer(props.customers[0]);
            return {
                ...state,
                customers: props.customers,
                Customer: props.customers[0],
                selectedCustomer: id,
                maintainer_logo: maintainer_logo,
                customer_logo: customer_logo
            }
        } else {
            return null
        }

    }
    handleRedirect = () => {

    }
    handleChange = (event) => {
        const val = parseInt(event.target.value);
        const cust = this.state.customers.find(x => x.id === val);
        this.props.setCustomer(cust);
        this.setState({
            selectedCustomer: event.target.value,
            Customer: cust,
        })
    };
    
    render() {
        
        const { isAuthenticated, user } = this.props.auth;
        const { t } = this.props;
        const { customers } = this.state;
        const vehiclesMenu = (<a className="nav-item nav-link" href="#/vehicles">{t("Vehicles")}</a>);
        const customersMenu = (<a className="nav-item nav-link" href="#/customers">{t("Customers")}</a>);
        const anprMenu = (<a className="nav-item nav-link" href="#/anprs">{t("Anprs")}</a>);
        const weighingMenu = (<a className="nav-item nav-link" href="#/weighing">{t("Weighing")}</a>);
        const applicationSettingsMenu = (<a className="nav-item nav-link" href="#/application_settings">{t("Settings")}</a>);
        const menuVehiclesActive = this.props.customer.menuVehiclesActive;
        const menuCustomersActive = this.props.customer.menuCustomersActive;
        const menuAnprActive = this.props.customer.menuAnprActive;
        const menuWeighigActive = this.props.customer.menuWeighigActive;
        const menuSettingsActive = this.props.customer.customer === undefined || user === null || user === undefined ? false : this.props.customer.customer.admins.includes(user.id);
        const companyForm = (<Form>
            <Form.Control size="sm" as="select" value={this.state.selectedCustomer.id} disabled={customers.length === 1} onChange={event => this.handleChange(event)}>
                {customers.map(v => (
                    <option key={v.id} value={v.id}>{v.name_short}</option>
                ))}
            </Form.Control>
        </Form>);
        //console.log(this.state);
        const authLinks = (
            <Fragment>
                {customers.length <= 1 ? '' : companyForm}
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        {menuVehiclesActive ? vehiclesMenu : ''}
                        {menuCustomersActive ? customersMenu : ''}
                        {menuAnprActive ? anprMenu : ''}
                        {menuWeighigActive ? weighingMenu : ''}
                        {menuSettingsActive ? applicationSettingsMenu : ''}
                        
                    </div>
                </div>

                <span className="navbar-text mr-3">
                    <strong>
                        {user ? `  ${t("Welcome")} ${user.first_name} ${user.last_name}` : ""}
                    </strong>
                </span>
                <ul className="navbar-nav ml-auto mt-2 mt-lg-0">

                    <li className="nav-item">
                        <button onClick={this.props.logoutUser} className="nac-link btn btn-info btn-sm text-light">{t("Logout")}</button>
                    </li>
                </ul>
            </Fragment>
        );
        const guestLinks = (
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <Link to="/login" className="nav-link">
                        {t("Login")}
                    </Link>
                </li>
            </ul>
        );
        return (
            <div>
                {this.state.showtransit && <TransitList />}
                <nav className="navbar navbar-expand-sm navbar-dark bg-primary fixed-top">
                    <div className="container">
                        <a className="navbar-brand" href="">
                            {this.state.maintainer_logo === null && <i className="fas fa-balance-scale" />}
                            {this.state.maintainer_logo !== null && <img height="30px" src={this.state.maintainer_logo} />}
                            {this.state.customer_logo !== null && <img height="30px" src={this.state.customer_logo} />}
                            &nbsp;miw.group ANPR <span className="badge badge-success">Ver.{this.props.version}</span>
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>

                        {isAuthenticated ? authLinks : guestLinks}
                    </div>
                </nav>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    version: state.versionReducer.version,
    auth: state.authReducer,
    message: state.mqttReducer.message,
    client: state.mqttReducer.mqttClient,
    customers: state.customersReducer.customers,
    customer: state.customersReducer.customer
});

export default connect(mapStateToProps, { getCustomers, setCustomer, logoutUser })(withTranslation()(Header))

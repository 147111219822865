import Paho from "paho-mqtt";
import {arrived, connected, disconnected} from "./actions/mqtt";

const reduxMqttMiddleware = config => ({ dispatch, getState }) => {
    console.log(getState());
    //const client = new Paho.Client('test.mosquitto.org', 8080,"");

    // set callback handlers


    return next => (action) => {
        next(action);
    };
};

export default reduxMqttMiddleware;
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getWeighings } from "../../actions/weighings";
import MUIDataTable from "mui-datatables";
import Lightbox from "react-image-lightbox";
import moment from "moment";
import ReactIntense from "react-intense";
export class Weighings extends Component {
  static propTypes = {
    weighings: PropTypes.array.isRequired,
    getWeighings: PropTypes.func.isRequired
  };

  state = {
    selectedWeighing: {
      vehicle: {},
      previousWeighing: {},
      anpr_transaction: {},
      device: {},
      images: [],
      signal: "",
      alibi: "",
      weight: ""
    },
    columns: [],
    tableStatePersist: {
      //Dynamic collection of props that are needed between table refreshes.
      searchText: "",
      filterList: [],
      columns: []
    }
  };
  handleChange = (action, tableState) => {
    //console.log("Table state changed || " + JSON.stringify(action));
    //console.log("CURRENT STATE: " + JSON.stringify(tableState));

    if (action !== "propsUpdate") {
      //Store new tableStatePersist only if not updating props
      this.setState({
        tableStatePersist: {
          searchText: tableState.searchText,
          filterList: tableState.filterList, //An array of filters for all columns
          columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
        }
      });
    }
  };
  getColumns = () => {
    //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
    let columns = [
      {
        name: "id",
        options: {
          display: "false"
        }
      },
      {
        name: "thumbs",
        options: {
          display: "false"
        }
      },
      {
        name: "imgs",
        label: "Photo",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            moment.locale("pl");
            //console.log(moment(tableMeta.rowData[3]).utc().format('DD-MM-YYYY HH:mm:ss'));
            //console.log(tableMeta.rowData);

            return (
              <ReactIntense
                src={tableMeta.rowData[2][0]}
                thumbnail={tableMeta.rowData[1][0]}
                title={
                  (tableMeta.rowData[3] + " - " + tableMeta.rowData[4] + "kg")
                }
                caption={moment(tableMeta.rowData[5]).utc().format('DD-MM-YYYY HH:mm:ss')}
              />
            );
          }
        }
      },
      {
        name: "anpr_transaction.plate",
        label: "Plate",
        options: {
          display: "true"
        }
      },
      {
        name: "weight",
        label: "Weight",
        options: {
          display: true
        }
      },
      {
        name: "created",
        label: "Date",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Fragment>
                {moment(value)
                  .utc()
                  .format("DD-MM-YYYY HH:mm:ss")}
              </Fragment>
            );
          }
        }
      }
    ];

    //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
    for (let i = 0; i < columns.length; i++) {
      //Assign the filter list to persist
      columns[i].options.filterList = this.state.tableStatePersist.filterList[
        i
      ];
      if (this.state.tableStatePersist.columns[i] !== undefined) {
        //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
        if (this.state.tableStatePersist.columns[i].hasOwnProperty("display"))
          columns[i].options.display = this.state.tableStatePersist.columns[
            i
          ].display;
        //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
        if (
          this.state.tableStatePersist.columns[i].hasOwnProperty(
            "sortDirection"
          )
        ) {
          //The sortDirection prop only permits sortDirection for one column at a time
          if (this.state.tableStatePersist.columns[i].sortDirection !== "none")
            columns[
              i
            ].options.sortDirection = this.state.tableStatePersist.columns[
              i
            ].sortDirection;
        }
      }
    }

    return columns;
  };
  getSearchText = () => {
    return this.state.tableStatePersist.searchText;
  };
  updateTimeout;
  componentDidMount() {
    this.props.getWeighings();
    this.updateWeighings();
  }
  updateWeighings = async () => {
    await this.props.getWeighings();
    this.updateTimeout = setTimeout(this.updateWeighings, 3000);
  };
  componentWillUnmount() {
    clearTimeout(this.updateTimeout);
  }
  handleRowClick = weighing => {
    this.setState({
      selectedWeighing: weighing
    });
  };

  //test
  render() {
    const tmpWeighings = this.props.weighings.map(weighing => {
      //console.log(weighing);
      weighing.imgs = weighing.images.map(image => {
        return image.image;
      });
      weighing.thumbs = weighing.images.map(image => {
        return image.thumbnail;
      });
      return weighing;
    });
    //console.log(tmpWeighings);
    return (
      <Fragment>
        <MUIDataTable
          columns={this.getColumns()}
          data={tmpWeighings}
          options={{
            searchText: this.getSearchText(),
            onTableChange: (action, tableState) =>
              this.handleChange(action, tableState),
            responsive: "scrollMaxHeight",
            onRowClick: (rowData, rowMeta) => {
              this.handleRowClick(this.props.weighings[rowMeta.dataIndex]);
            },
            filterType: "checkbox"
          }}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  weighings: state.weighingsReducer.weighings
});

export default connect(mapStateToProps, { getWeighings })(Weighings);

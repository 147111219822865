import React, { Fragment } from 'react'
import Weighing from './Weighing'
import Weighings from "./Weighings";
import WeighingCards from "./WeighingCards";
import {connect} from "react-redux";
import {getCustomers, setCustomer} from "../../actions/customers";
import {logoutUser} from "../../actions/auth";
import {withTranslation} from "react-i18next";
import PropTypes, {element} from 'prop-types'
import {Container} from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

function Dashboard(props) {
    const weighing = (<Weighing/>);
    const history = useHistory();
    const handleRedirect = () => history.push('/application_settings');
    return (
        <Fragment>
            <Container fluid='md' className="mt-5">
                {props.customer.onlineWeighing ? weighing : ''}
                <WeighingCards handleRedirect={handleRedirect}/>
            </Container>
        </Fragment>
    )
}


const mapStateToProps = state => ({
    customer: state.customersReducer.customer
});

export default connect(mapStateToProps, { getCustomers, setCustomer, logoutUser })(withTranslation()(Dashboard))

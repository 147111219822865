import axios from 'axios';

import {GET_TRANSITS, UPDATE_TRANSIT_IN_PROGRESS} from "./types";
import { createMessage, returnErrors } from "./messages";
import { tokenConfig} from "./auth";

//require('axios-debug')(axios);

// GET VEHICLES
export const getTransits = () => (dispatch, getState) => {
    const update = getState().transitsReducer.update
    if (!update) {
        axios.get('/api/v1/transits/', tokenConfig(getState))
            .then(res => {
                dispatch({
                    type: GET_TRANSITS,
                    payload: res.data
                });
            }).catch(err => dispatch(returnErrors(err.response.data,err.response.status)));
    }
};

export const editTransitStart = () => (dispatch, getState) => {
    dispatch ({
        type: UPDATE_TRANSIT_IN_PROGRESS,
        payload: true
    })
}
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAnprs } from "../../actions/anprs";
import MUIDataTable from "mui-datatables";
import { SRLWrapper } from "simple-react-lightbox"; // Import SRLWrapper
import ReactIntense from 'react-intense';
import moment from 'moment';
import {withTranslation} from "react-i18next";
import {Form} from "../vehicles/Form";
//import Form from './Form';

export class Anprs extends Component {

    static propTypes = {
        anprs: PropTypes.array.isRequired,
        getAnprs: PropTypes.func.isRequired
    };
    state = {
        selectedAnpr: {
            id: '',
            vehicle: '',
            image: '',
            plate: '',
            created: '',
        },
        columns: [],
        tableStatePersist: { //Dynamic collection of props that are needed between table refreshes.
            searchText: '',
            filterList: [],
            columns: []
        },
    };
    handleChange = (action, tableState) => {
      //console.log("Table state changed || " + JSON.stringify(action));
      //console.log("CURRENT STATE: " + JSON.stringify(tableState));

      if(action !== 'propsUpdate') { //Store new tableStatePersist only if not updating props
        this.setState({
          tableStatePersist: {
            searchText: tableState.searchText,
            filterList: tableState.filterList, //An array of filters for all columns
            columns: tableState.columns //We can pull column-specific options from this array, like display and sortDirection
          },
        });
      }
    };
    getColumns = () => {
        const { t } = this.props;
      //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
      let columns = [
            {
              name: "id",
              options: {
                  display: 'false'
              }
            },
            {
                name: "image",
                label: t("Image"),
                options: {
                    selectableRows: false,
                    filter: false,
                    customBodyRender: (value,tableMeta, updateValue) => {
                            moment.locale('pl');
                            //console.log(moment(tableMeta.rowData[3]).utc().format('DD-MM-YYYY HH:mm:ss'));
                        return (
                            <ReactIntense src={value} title={tableMeta.rowData[2]} caption={moment(tableMeta.rowData[3]).utc().format('DD-MM-YYYY HH:mm:ss')} />
                        )
                    }
                }
            },
            {
                name: "plate",
                label: t("Plate"),
                options: {},
            },
            {
                name: "created",
                label: t("Date"),
                options: {
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <Fragment>{moment(value).utc().format('DD-MM-YYYY HH:mm:ss')}</Fragment>
                        )
                    }
                }
            },

        ];

      //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
      for(let i = 0; i < columns.length; i++) {
        //Assign the filter list to persist
        columns[i].options.filterList = this.state.tableStatePersist.filterList[i];
        if(this.state.tableStatePersist.columns[i] !== undefined) {
          //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
          if(this.state.tableStatePersist.columns[i].hasOwnProperty('display'))
            columns[i].options.display = this.state.tableStatePersist.columns[i].display;
          //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
          if(this.state.tableStatePersist.columns[i].hasOwnProperty('sortDirection')) {
            //The sortDirection prop only permits sortDirection for one column at a time
            if(this.state.tableStatePersist.columns[i].sortDirection !== 'none')
              columns[i].options.sortDirection = this.state.tableStatePersist.columns[i].sortDirection;
          }
        }
      }

      return columns;
    };
    getSearchText = () => {
      return this.state.tableStatePersist.searchText;
    };
    updateTimeout;
    componentDidMount() {
        this.updateAnprs();
        document.addEventListener('mousedown', userEvent => {
        const parentClass = userEvent.path[0].parentNode.className;
        if (parentClass === 'ri-container') document.body.style.overflow = null
        })
    }

    updateAnprs  = async () => {
      await this.props.getAnprs();
      this.updateTimeout = setTimeout(this.updateAnprs, 3000)
    };
    componentWillUnmount() {
        clearTimeout(this.updateTimeout);
    }

    handleRowClick = anpr => {
        this.setState({
            selectedAnprs: anpr
        });
    };


    render() {
        return (

            <Fragment>
                    <MUIDataTable
                        columns={this.getColumns()}
                        data = {this.props.anprs}
                        options = {{
                            searchText: this.getSearchText(),
                            onTableChange: (action, tableState) => this.handleChange(action, tableState),
                            responsive: 'scrollFullHeight',
                            onRowClick: (rowData, rowMeta) => {
                                this.handleRowClick(this.props.anprs[rowMeta.dataIndex]);
                            }
                        }}
                    />
            </Fragment>

        );
    }
}

const mapStateToProps = state => ({
    anprs: state.anprReducer.anprs
});

export default connect(mapStateToProps, { getAnprs, })(withTranslation()(Anprs))
import React, { Fragment } from 'react'
import Customers from './Customers';
import Form from './Form';

export default function Dashboard() {
    return (
        <Fragment>
            <div className="container mt-5">
                <Form />
                <Customers />
            </div>
        </Fragment>
    )
}

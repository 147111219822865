import {
    MQTT_CONNECTED,
    MQTT_ARRIVED,
    MQTT_DISCONNECTED,
    UPDATE_VEHICLE,
    MQTT_SUBSCRIBED,
    MQTT_UNSUBSCRIBED
} from "./types";
import Paho from "paho-mqtt";

// called when the client connects
export const mqttConnected = (client) => (dispatch, getState) => {
  dispatch({
      type: MQTT_CONNECTED,
      payload: client
  });
};
export const mqttConnect = (host,port,Userid) => (dispatch, getState) => {
    const client = new Paho.Client(host, port,Userid);

    client.onConnectionLost = onConnectionLost;
    client.onMessageArrived = onMessageArrived;

    // connect the client
    client.connect({
        onSuccess:onConnect,
        userName: "solace-cloud-client",
        password: "sn6vq7f0b034ep8qppmkgqn4me",
        useSSL: true

    });


    // called when the client connects
    function onConnect() {
      // Once a connection has been made, make a subscription and send a message.
      console.log("onConnect");
      //client.subscribe("anpr_test");
      dispatch(mqttConnected(client));
      if (getState().mqttReducer.subscribed !== '') {
          dispatch(mqttSubscribe(getState().mqttReducer.subscribed))
      }
      //let message = new Paho.Message("Hello");
      //message.destinationName = "anpr_test";
      //client.send(message);
    }

    // called when the client loses its connection
    function onConnectionLost(responseObject) {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:"+responseObject.errorMessage);
        dispatch(mqttDisconnected(responseObject));
        client.connect({onSuccess:onConnect});
      }
    }
    function onMessageArrived(message) {
      dispatch(mqttArrived(message));
    }
};
// called when the client loses its connection
export const mqttDisconnected = (responseObject) => (dispatch, getState) =>{
  if (responseObject.errorCode !== 0) {
    dispatch({
      type: MQTT_DISCONNECTED,
      payload: responseObject
    });

  }
};

// called when a message arrives
export const mqttArrived = (message) => (dispatch, getState) => {
  dispatch({
      type: MQTT_ARRIVED,
      payload: message
  });
};

export const mqttDisconnect = () => (dispatch, getState) => {
    if (getState().mqttReducer.mqttClient !== undefined) {
        getState().mqttReducer.mqttClient.disconnect();
    }
    dispatch(mqttUnubscribe(getState().mqttReducer.subscribed));
    dispatch(mqttDisconnected({errorCode: 1, errorMessage: "User"}))
};

export const mqttSubscribe = (topic) => (dispatch, getState) => {
    if (getState().mqttReducer.subscribed !== ''){
        getState().mqttReducer.mqttClient.unsubscribe(getState().mqttReducer.subscribed);
    }

    getState().mqttReducer.mqttClient.subscribe(topic);
    dispatch({
        type: MQTT_SUBSCRIBED,
        payload: topic
    });
};

export const mqttUnubscribe = (topic) => (dispatch, getState) => {
    //getState().mqttReducer.mqttClient.unsubscribe(topic);
    dispatch({
        type: MQTT_UNSUBSCRIBED,
        payload: topic
    });
};



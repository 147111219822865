import axios from 'axios';
import { tokenConfig} from "./auth";

import { GET_ANPRS } from "./types";
import {createMessage, returnErrors} from "./messages";

// GET CUSTOMERS
export const getAnprs = () => (dispatch, getState) => {
    axios.get('/api/v1/anpr/', tokenConfig(getState))
        .then(res => {
            dispatch({
                type: GET_ANPRS,
                payload: res.data
            });
        }).catch(err => console.log(err));
};
import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";
import { Col, Row, Form, FormLabel, FormControl, Button, InputGroup } from 'react-bootstrap';
import { getTargets, updateTarget } from "../../actions/targets"

function arrayEquals(a, b) {
    return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
  }

function areTheseObjectsEqual(first, second) {
    const al = Object.getOwnPropertyNames(first);
    const bl = Object.getOwnPropertyNames(second);
    console.log(first)
    console.log(second)
    // Check if the two list of keys are the same
    // length. If they are not, we know the objects
    // are not equal.
    if (al.length !== bl.length) return false;
    
    // Check that all keys from both objects match
    // are present on both objects.
    const hasAllKeys = al.every(value => !!bl.find(v => v === value));
  
    // If not all the keys match, we know the
    // objects are not equal.
    if (!hasAllKeys) return false;
    
    // We can now check that the value of each
    // key matches its corresponding key in the
    // other object.
    for (const key of al) {
        if (Array.isArray(first[key])) {
            if (!arrayEquals(first[key], second[key])) return false  
        } else  {
            if (first[key] !== second[key]) return false
        }
    };
    // If the object hasn't return yet, at this
    // point we know that the objects are the
    // same
    return true;
  }

export class TargetSettings extends Component {

    state = {
        targets: [],
        
    };


    handleChange = (target, event) => {
        //console.log(target,event)
        const {name, value, type, checked} = event.target
        // console.log(name , value, type, checked)
        type === "checkbox" ? // Ternary sprawdza czy zmieniany jest checkbox czy inny element (checkbox ma swoja wartosc checked, true lub false)
            this.setState(prevState => ({
                ...this.state,
                targets: prevState.targets.map(t => t.id === target.id ? {...target, [name]: checked} : t)
            }))
            :
            this.setState(prevState => ({
                ...this.state,
                targets: prevState.targets.map(t => t.id === target.id ? {...target, [name]: value} : t) //ten dziwny [name] to nazwa klucza ze zmiennej
            }))
    }

    handleSubmit = (target, event) => {
        this.props.updateTarget(target)
    }

    // onSettingChange = (event) => {
    //     const settingid = event.target.getAttribute("settingid");
    //     const value = event.target.value; 
    //     this.setState(prevState => ({
    //         ...this.state,
    //         applications: prevState.applications.map(application => ({
    //             ...application,
    //             settings: application.settings.map(setting => setting.id == settingid ? {...setting, settingValue: value}: setting)
    //         }))
    //     }))
    // }
    
    componentDidMount() {
        this.props.getTargets() //Dzięki temu załadują się targety do reduxa, ale jak zrobie zaraz potem setState to nic w reduxie jeszcze nie bedzie 
    }
    componentDidUpdate(prevProps) { //tutaj sprawdzam czy się zmieniły targetsy i dopiero teraz odpalam setState
        console.log(this.props, prevProps)
        if (this.props.targets !== prevProps.targets) {
            this.setState({
                ...this.state,
                targets: [...this.props.targets]
            })
        }
    }
    render() {
        const targets = this.state.targets
        console.log(targets)
        const { t } = this.props
        return (
            <Form>
                {targets.map((target, key) => (
                    <div key={key}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Target Name</Form.Label>
                                <Form.Control name="name" type="text" value={target.name} onChange={(event) => this.handleChange(target,event)} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Target ShortName</Form.Label>
                                <Form.Control name="short_name" type="text" value={target.short_name} onChange={(event) => this.handleChange(target,event)} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Min delta</Form.Label>
                                <Form.Control name="min_fork" type="number" value={target.min_fork} onChange={(event) => this.handleChange(target,event)} />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Max delta</Form.Label>
                                <Form.Control name="max_fork" type="number" value={target.max_fork} onChange={(event) => this.handleChange(target,event)} />
                            </Form.Group>
                        </Form.Row>

                        <Form.Check inline label="SMS to Driver" checked={target.sms_to_driver} name="sms_to_driver" type="checkbox" id={key} onChange={(event) => this.handleChange(target,event)}/>

                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Form.Control name="sms_to_list" as="textarea" rows={2} value={target.sms_to_list} onChange={(event) => this.handleChange(target,event)} /> 
                        </Form.Group>
                        {this.props.targets.find(pt => pt.id === target.id) !== target &&
                        <Button onClick={this.handleSubmit.bind(this, target)}>{t("Save")}</Button>
                        }
                        {target.id != targets.length &&
                        <hr />}


                        {/* {application.settings.map((setting, key) => (
                            <Row key={key} className="align-items-end pb-3">
                                <Col>
                                    <FormLabel column={1}>
                                        {t(setting.verboseSettingName)}
                                    </FormLabel>
                                    <FormControl onChange={this.onSettingChange} type="number" settingid={setting.id} value={setting.settingValue} />
                                </Col>
                                <Col xs={1}>
                                    {!areTheseObjectsEqual(this.props.customer.applications.find(app => app.id === application.id).settings.find(set => set.id === setting.id),setting) && 
                                    <Button onClick={this.onSaveSetting.bind(this, setting)}>{t("Save")}</Button>
                                }
                                </Col>
                            </Row>
                        ))} */}
                    </div>
                ))}
            </Form>
        )

    }
}    
const mapStateToProps = state => ({
    targets: state.targetsReducer.targets,
});
export default connect(mapStateToProps,{updateTarget, getTargets})(withTranslation()(TargetSettings));
